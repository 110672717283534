// ChartStep.jsx
import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Button, Loader, Segment } from 'semantic-ui-react';
import Chart from 'react-apexcharts';
import {get_average_anchor} from './calculations'



const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const ChartContainer = styled.div`
  animation: ${slideIn} 0.5s ease-out;
  text-align: center;
  position: relative; /* To contain the hidden container */
`;

const HiddenChartContainer = styled.div`
//   position: absolute;
//   top: -10000px; /* Position it off-screen */
//   left: -10000px;
  width: 2400px; /* Desired high resolution width */
  height: 1600px; /* Desired high resolution height */
//   overflow: hidden; /* Prevent scrollbars */
`;

const ChartStep = ({ onComplete,data,filters,anchor,core_data }) => {
  const chartRef = useRef(null);
  const hiddenChartRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [chartSeries, setChartSeries] = useState([]);
  const scaleFactor = 2; // Define your scaling factor


  const [chartOptions, setChartOptions] = useState(options);
  const [hiddenChartOptions, setHiddenChartOptions] = useState(options2);


  useEffect(() => {
   
    // Simulate data fetching or processing
    const timer = setTimeout(() => {
        const { series, categories, yMin, yMax } = get_average_anchor(data, filters, anchor, core_data);
         // Update the series and categories
         setChartSeries(series);
         setChartOptions((prevOptions) => ({
           ...prevOptions,
           xaxis: {
             ...prevOptions.xaxis,
             categories: categories,
           },
           yaxis: {
             ...prevOptions.yaxis,
             min: yMin > 0 ? yMin : 0,
             max: yMax,
           },
         }));

         setHiddenChartOptions((prevOptions) => ({
            ...prevOptions,
            xaxis: {
              ...prevOptions.xaxis,
              categories: categories,
            },
            yaxis: {
              ...prevOptions.yaxis,
              min: yMin > 0 ? yMin : 0,
              max: yMax,
            },
          }));

      setLoading(false);
    }, 2000); // 2 seconds loading


    setTimeout(() => {
        handleCaptureChart();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleCaptureChart = () => {
    if (hiddenChartRef.current) {     

        hiddenChartRef.current.chart
        .dataURI({
          width: 2400 , // Double the width
          height: 1600, // Double the height
          scale: 1, // Increase scale to enhance resolution
        })
        .then(({ imgURI }) => {
            console.log(imgURI)
        //   onComplete(imgURI);
        })
    }
  };

  return (
    <ChartContainer>
      {loading ? (
        <Loader active inline="centered" size="large">
          Creating your data visualization...
        </Loader>
      ) : (
        <>
          <Chart
            ref={chartRef}
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={chartOptions.chart.height} // Use dynamic height
            width={chartOptions.chart.width} // Use dynamic width
          />
            {/* Hidden High-Resolution Chart for Image Capture */}
            <HiddenChartContainer>
            <Chart
              ref={hiddenChartRef}
              options={{ ...hiddenChartOptions, width: 2400, height: 1600 }} // High-res dimensions
              series={chartSeries}
              type="bar"
              height={1600}
              width={2400}
            />
          </HiddenChartContainer>
        </>
      )}
    </ChartContainer>
  );
};

export default ChartStep;



const options2 = {
    chart: {
      id: 'report-chart',
      fontFamily: 'Raleway, sans-serif',
      width: 2400, // Increased width
      height: 1600, // Increased height
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
      },
      background: 'transparent',
    },
    colors: ['#2D70E2'],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        barHeight: '70%',
      },
    },
    legend: {
      show: false,
    },
    fill: {
      type: 'solid',
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding:{
        top:100,
        right:100,
        bottom:300,
        left:100
      }
    },xaxis: {
        type: 'category',
        title: {
          text: 'Factors',
          offsetY: 200,
          style: {
            fontFamily: 'Raleway, sans-serif',
            fontSize:40
          }
        },
        labels: {
          style: {
            fontFamily: 'Raleway, sans-serif',
            fontSize:40
          },
          offsetY:20,
          rotate: -45,
          maxHeight: 100,
          formatter: function (val) {
            return val.length > 30 ? val.substring(0, 27) + '...' : val;
          },
        },
      },
      yaxis: {
        labels: {
        offsetX: 80,
          style: {
            fontFamily: 'Raleway, sans-serif',
            fontSize:40
          },
          formatter: function (value) {
            return value.toFixed(0);
          },
        },
        title: {
          text: 'Difference (Your Score - Benchmark)',
          offsetX:-30,
          style: {
            fontFamily: 'Raleway, sans-serif',
            fontSize:40,
          },
        },
        min: 0, // Default value; will be updated dynamically
      max: 100, // Default value; will be updated dynamically
      decimalsInFloat:0,
      forceNiceScale: true,
      tickAmount: 6,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (value) {
            return value.toFixed(1);
          },
        },
        style: {
          fontFamily: 'Raleway, sans-serif',
        },
      },
      title: {
        text: 'Difference from Benchmark by Factor',
        align: 'center',
        style: {
          fontFamily: 'Raleway, sans-serif',
          fontSize:40
        },
      },
    };



const options = {
        chart: {
          id: 'report-chart',
          fontFamily: 'Raleway, sans-serif',
          width: 800, // Increased width
          height: 600, // Increased height
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
          },
          margin: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50,
          },
          background: 'transparent',
        },
        colors: ['#2D70E2'],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 5,
            barHeight: '70%',
          },
        },
        legend: {
          show: false,
        },
        fill: {
          type: 'solid',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },xaxis: {
            type: 'category',
            title: {
              text: 'Factors',
            },
            labels: {
              style: {
                fontFamily: 'Raleway, sans-serif',
                fontSize: '12px',
              },
              rotate: -45,
              maxHeight: 100,
              formatter: function (val) {
                return val.length > 30 ? val.substring(0, 27) + '...' : val;
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontFamily: 'Raleway, sans-serif',
              },
              formatter: function (value) {
                return value.toFixed(0);
              },
            },
            title: {
              text: 'Difference (Your Score - Benchmark)',
            },
            min: 0, // Default value; will be updated dynamically
          max: 100, // Default value; will be updated dynamically
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: function (value) {
                return value.toFixed(1);
              },
            },
            style: {
              fontFamily: 'Raleway, sans-serif',
            },
          },
          title: {
            text: 'Difference from Benchmark by Factor',
            align: 'center',
            style: {
              fontFamily: 'Raleway, sans-serif',
            },
          },
        };