// Will be called "Data Stories"
import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import List from "./List";
import Create from "./Create";
import Success from "../../reports/components/Success";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../reports/components/DeleteModal";

import {
  SG_DELETE_DEBRIEF_PDF_REPORT,
  SG_DELETE_DEBRIEF_PDF_REPORT_ITEM,
  SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES,
  SG_GET_DEBRIEF_PDF_REPORT,
} from "constants/actions";

// Create a function that takes the data object and a type as arguments
// return a list of debrief_pdf_report objects that have the template_id matching the id of the template with the given type
// the first step is to create a list of ids of the templates that have the type equal to the given type
// then filter the debrief_pdf_report objects using the list of ids
function filterDebriefPdfReport(data, type) {
  const templateIds = data?.templates?.response
    ?.filter((template) => {
      return template?.folder === "insights_v1";
    })
    ?.map((template) => template?.id);
  return data?.debrief_pdf_report?.filter((report) =>
    templateIds?.includes(report?.template_id)
  );
}

const InsightsReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stage, setStage] = useState(0);
  const [pdfList, setPdfList] = useState([]);
  const [pdfId, setPdfId] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [deletePending, setDeletePending] = useState(null);

  const { pdfReports } = useSelector(
    (state) => ({
      pdfReports: state.debrief_pdf_report,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES,
    });

    dispatch({
      type: SG_GET_DEBRIEF_PDF_REPORT,
    });
  }, []);

  useEffect(() => {
    if (pdfReports && pdfReports?.created) {
      setShowSuccess(true);
    } else {
      setShowSuccess(false);
    }

    if (pdfReports?.debrief_pdf_report && pdfReports?.templates) {
      setPdfList(filterDebriefPdfReport(pdfReports, "insights_v1"));
    }
  }, [pdfReports]);

  const createPdf = (id) => {
    setPdfId(id);
    setStage(1);
  };

  useEffect(() => {
    let pathname = location.pathname.split("/");

    switch (pathname[pathname.length - 1]) {
      case "list":
        setStage(0);
        break;
      case "create":
        setStage(1);
        break;
      default:
        setStage(0);
    }
  }, [location.pathname]);

  const deletePendingConfirm = (id) => {
    dispatch({
      type: SG_DELETE_DEBRIEF_PDF_REPORT,
      payload: id,
    });
    setDeleteId(null);
  };

  const deleteItemConfirm = (id) => {
    dispatch({
      type: SG_DELETE_DEBRIEF_PDF_REPORT_ITEM,
      payload: id,
    });
    setDeleteItem(null);
  };

  const deleteModal = (
    <DeleteModal
      deletePending={deletePending}
      deleteItem={deleteItem}
      deletePendingConfirm={deletePendingConfirm}
      deleteItemConfirm={deleteItemConfirm}
    />
  );

  return (
    <Container>
      {showSuccess && (
        <Success
          open={showSuccess}
          onClose={() => {
            dispatch({
              type: "CLEAR_DEBRIEF_PDF_REPORT",
            });
            setShowSuccess(false);
          }}
          navFunction={() => {
            navigate("/leader/audit/simple/insights/create");
          }}
        />
      )}
      {stage === 0 && (
        <List
          setDeleteId={setDeleteId}
          loading={loading}
          setDeleteItem={setDeleteItem}
          pdfList={pdfList}
        />
      )}
      {stage === 1 && (
        <Create
          pdfId={pdfId}
          setStage={setStage}
          setPdfId={setPdfId}
          setConfirmDelete={setConfirmDelete}
        />
      )}
      {deleteModal}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  font-family: "Raleway";
  transition: all 0.3s ease-in-out;
`;

export default InsightsReport;
