/**
 * @description Chart options
 *
 */

export const chartDownloadOptions = {
  toolbar: {
    show: true,
    offsetX: 0,
    offsetY: 0,
    tools: {
      download: true,
      selection: true,
      zoom: false,
      zoomin: false,
      zoomout: false,
      pan: false,
      reset: false | '<img src="/static/icons/reset.png" width="20">',
      //   customIcons: []
    },
    export: {
      csv: {
        filename: undefined,
        columnDelimiter: ",",
        headerCategory: "category",
        headerValue: "value",
        categoryFormatter(x) {
          return new Date(x).toDateString();
        },
        valueFormatter(y) {
          return y;
        },
      },
      svg: {
        filename: undefined,
      },
      png: {
        filename: undefined,
      },
    },
    autoSelected: "zoom",
  },
};

export function injectDownloadOptions(apexChartOptionsObject) {
  return {
    ...apexChartOptionsObject,
    chart: {
      ...apexChartOptionsObject.chart,
      toolbar: {
        ...apexChartOptionsObject.chart?.toolbar,
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false | '<img src="/static/icons/reset.png" width="20">',
        },
        export: {
          csv: {
            columnDelimiter: ",",
            headerCategory: "Category",
            dateFormatter: (timestamp) =>
              new Date(timestamp).toLocaleDateString(),
          },
          svg: { filename: "chart_export" },
          png: { filename: "chart_export" },
        },
      },
    },
  };
}