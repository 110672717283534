import React from "react";
import Thematic from "./Thematics";
import Participation from "./Participation";
import Factor from "./Factor";
import Score from "./Score";

const TemplateBuilder = ({ selected, slideData, updateSlideData,loading }) => {
  switch (selected) {
    case 'Thematic Analysis':
      return <Thematic slideData={slideData} updateSlideData={updateSlideData} loading={loading} />;
    case 'Participation':
      return <Participation data={slideData} updateSlideData={updateSlideData} loading={loading}/>;
    case 'Factor Analysis':
      return <Factor slideData={slideData} updateSlideData={updateSlideData} loading={loading}/>;
    case 'Score Overview':
        return <Score slideData={slideData} updateSlideData={updateSlideData} loading={loading}/>;
    default:
      return null;
  }
};

export default TemplateBuilder;