import { call, put, all, takeLatest, delay } from "redux-saga/effects";
import * as api from "constants/api";

import {
  DEBRIEF_SCHEDULES_PENDING,
  CULTURE_AUDIT_REPORT_PENDING,
  GET_DEBRIEF_SCHEDULES,
  SG_GET_DEBRIEF_SCHEDULES,
  GET_CULTURE_AUDIT_REPORTS,
  SG_GET_CULTURE_AUDIT_REPORTS,
  DELETE_DEBRIEF_SCHEDULE,
  SG_DELETE_DEBRIEF_SCHEDULE,
  FETCH_DEBRIEF_SCHEDULE,
  SG_FETCH_DEBRIEF_SCHEDULE,
  EDIT_DEBRIEF_SCHEDULE,
  SG_EDIT_DEBRIEF_SCHEDULE,
  ADD_NEW_DEBRIEF_SCHEDULE,
  RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
  SG_ADD_DEBRIEF_SCHEDULE,
  GET_AUDIT_ELIGIBLE,
  SG_GET_AUDIT_ELIGIBLE,
  ADD_USER_TO_SURVEY,
  SG_ADD_USER_TO_SURVEY,
  EXTEND_SURVEY_TIME,
  SG_EXTEND_SURVEY_TIME,
  ALL_ERRORS,
  FIND_DEBRIEF_SCHEDULE,
  SG_FIND_DEBRIEF_SCHEDULE
} from "constants/actions";

function* loadmethods(action) {
    yield put({ type: DEBRIEF_SCHEDULES_PENDING });

  try {
    const json = yield call(api.GET_DEBRIEF_SCHEDULES, action.payload);
    // console.log(json.data)
    yield put({ type: GET_DEBRIEF_SCHEDULES, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_DEBRIEF_SCHEDULES, loadmethods);
}

const THROTTLE_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
let lastApiCallTime = 0;

function* loadreports(action) {
  // Check if enough time has passed since the last API call
  const currentTime = Date.now();

  if (currentTime - lastApiCallTime >= THROTTLE_TIME) {
    yield put({ type: CULTURE_AUDIT_REPORT_PENDING });

    try {
      const json = yield call(api.GET_CULTURE_AUDIT_REPORTS, action.payload);
      yield put({ type: GET_CULTURE_AUDIT_REPORTS, payload: json.data });

      // Update the last API call time
      lastApiCallTime = currentTime;
    } catch (e) {
      yield put({ type: ALL_ERRORS, payload: e.response });
    }
  } else {
    // Optionally, you can yield a delay for user experience if they keep triggering the action
    yield delay(THROTTLE_TIME - (currentTime - lastApiCallTime));
  }
}

export function* reportLoad() {
  yield takeLatest(SG_GET_CULTURE_AUDIT_REPORTS, loadreports);
}

function* auditEligible(action) {
  yield put({ type: DEBRIEF_SCHEDULES_PENDING });
  try {
    const json = yield call(api.GET_AUDIT_ELIGIBLE, action.payload);
    // console.log(json.data)
    yield put({ type: GET_AUDIT_ELIGIBLE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* AuditEligibleLoad() {
  yield takeLatest(SG_GET_AUDIT_ELIGIBLE, auditEligible);
}

function* userAddToSurvey(action) {
  yield put({ type: DEBRIEF_SCHEDULES_PENDING });
  try {
    const json = yield call(api.ADD_DEBRIEF_SCHEDULE_ADD_USER, action.payload);
    // console.log(json.data)
    yield put({ type: ADD_USER_TO_SURVEY, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* userAddToSurveyLoad() {
  yield takeLatest(SG_ADD_USER_TO_SURVEY, userAddToSurvey);
}

function* userExtendSurvey(action) {
  yield put({ type: DEBRIEF_SCHEDULES_PENDING });
  try {
    const json = yield call(api.ADD_DEBRIEF_SCHEDULE_EXTENDED_TIME, action.payload);
    // console.log(json.data)
    yield put({ type: EXTEND_SURVEY_TIME, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* userExtendSurveyLoad() {
  yield takeLatest(SG_EXTEND_SURVEY_TIME, userExtendSurvey);
}


function* fetchmethods(action) {
  yield put({ type: DEBRIEF_SCHEDULES_PENDING });
  try {
    const json = yield call(api.FETCH_DEBRIEF_SCHEDULE, action.payload);
    yield put({ type: FETCH_DEBRIEF_SCHEDULE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_DEBRIEF_SCHEDULE, fetchmethods);
}

function* updatemethods(action) {
  yield put({ type: DEBRIEF_SCHEDULES_PENDING });
  try {
    const json = yield call(api.EDIT_DEBRIEF_SCHEDULE, action.payload);
    yield put({ type: EDIT_DEBRIEF_SCHEDULE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}


export function* methodUpdate() {
  yield takeLatest(SG_EDIT_DEBRIEF_SCHEDULE, updatemethods);
}

function* addmethods(action) {
  yield put({ type: DEBRIEF_SCHEDULES_PENDING });
  yield put({ type: RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE });
  try {
    const json = yield call(api.ADD_DEBRIEF_SCHEDULE, action.payload);
    yield put({ type: ADD_NEW_DEBRIEF_SCHEDULE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_DEBRIEF_SCHEDULE, addmethods);
}

function* deletemethods(action) {
  yield put({ type: DEBRIEF_SCHEDULES_PENDING });
  try {
    yield call(api.DELETE_DEBRIEF_SCHEDULE, action.payload);
    yield put({ type: DELETE_DEBRIEF_SCHEDULE, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_DEBRIEF_SCHEDULE, deletemethods);
}

function* findmethods(action) {
  yield put({ type: DEBRIEF_SCHEDULES_PENDING });
  try {
    const json = yield call(api.GET_DEBRIEF_SCHEDULES, action.payload);
    // console.log(json.data)
    yield put({ type: FIND_DEBRIEF_SCHEDULE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFind() {
  yield takeLatest(SG_FIND_DEBRIEF_SCHEDULE, findmethods);
}

export default function* index() {
  yield all([
    methodLoad(),
    reportLoad(),
    AuditEligibleLoad(),
    userAddToSurveyLoad(),
    userExtendSurveyLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
    methodFind()
  ]);
}
