// AIContentStep.jsx
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Loader, Segment } from 'semantic-ui-react';
import make_call from './Request'
import {parseMarkdown} from './MarkdownParser'

// Styled Components for animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const AIContainer = styled.div`
  animation: ${fadeIn} 0.5s ease-in;
  text-align: left;
`;

const AIContentStep = ({ chartURI, onComplete }) => {
  const [loading, setLoading] = useState(true);
  const [aiMessage, setAiMessage] = useState('');
  const [isInitialCall, setIsInitialCall] = useState(true);
  const [history, setHistory] = useState([]);
  const [selectedFactor, setSelectedFactor] = useState({ name: 'Culture' }); // Example data
  const [selectedOutcomeName, setSelectedOutcomeName] = useState('Employee Engagement'); // Example data
  const [context, setContext] = useState(['Team Dynamics', 'Leadership Strategies']); // Example data

  useEffect(() => {
    if (chartURI) {
      make_gpt_call();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartURI]);

  const make_gpt_call = async (userFeedback = "") => {
    setLoading(true);
    setAiMessage("");

    // Prepare updated history, adding initial message on the first call
    const updatedHistory = isInitialCall
      ? [
          ...history,
          {
            role: "user",
            content: `
The following is the feedback data for the ${selectedFactor.name} culture factor ${selectedOutcomeName ? `and the ${selectedOutcomeName} outcome` : ""}:
${userFeedback}

Based on the feedback from the survey, your role is to provide micro action plans to address the issues brought up
in the feedback. 

${selectedOutcomeName? `Our ultimate goal is to improve ${selectedOutcomeName} via ${selectedFactor.name}` : ""}

The micro actions should be a gentle nudge, short tip, experiment, or habit that the leader or manager can intentionally practice in their day-to-day work. Each micro action is meant to be repetitive to allow for consistency so that it becomes a habit for the leader. Each micro action should only take the leader ten minutes to practice. The micro actions are also meant to be achieved within 12 weeks so take this into account when selecting and describing each action. As such, the micro actions must be specific, time-oriented when possible, and concrete to positively impact the work environment and employees. The micro action should be descriptive and highlight a specific scenario that is common for a departmental leader or manager like a one-on-one meeting, for example.  When applicable, please incorporate a suggested “what you could say” or sample probing question that could be asked within the associated micro action. A leader should be able to read the micro action and know exactly how to proceed. Therefore, the micro actions must be focused on things within the leader or managers sphere of influence and within their control. It should require little to no tangible resources or reliance on leaders above them. The actions should be in a bulleted list and be one sentence each. 

The recommendation and micro actions should not include any suggestions for competitor platforms and/or surveys/questionnaires/assessments in the employee engagement, human resources, or people operations space. 

The recommendations and micro actions should also use accessible, easy terms and avoid jargon while still sounding professional so that any leader/manager can easily understand it. Use a personal, everyday tone so the leader can resonate with the importance of acting. Use unbiased language and do not rely on stereotypes.

Here’s an example to mimic when developing the why and micro actions:

Be intentional about Inclusion and Belonging

Unless people consciously try to be inclusive, exclusion can occur by accident. Even unintentional ambient cues can undermine people's sense of belonging. For example, Walton, Murphy and Ryan point out that 'Walls lined with photos of senior executives that exclude women and people of color may cause members of underrepresented groups to doubt their prospects. 

Developing belonging should not only happen amongst peer groups.Those who have a trusting relationship with a mentor (or manager) are better able to take advantage of critical feedback and other opportunities to learn. The benefits of these trusting relationships are often greater amongst people from stigmatized groups.

During team meetings this month, check in and ask yourself, "Am I making others feel they belong where we are?" Being mindful of our own actions can influence how others perceive their sense of belonging.
If a team meeting is the best way to collaborate, set aside a few minutes for all participants to put their ideas on post-it notes and have each participant stand up and put their ideas on a board, grouping them as they go.
Engage in conscious amplification at least two times in the next two weeks by repeating an idea of an employee to pull it, assign proper ownership, give it credibility and validate its worthiness of being discussed within your team.
Create connections, not conflict, in tough conversations
Words are powerful. Even with the best intentions, we can say or do things that shut down dialogue before it gets started. It's especially easy to do this when the topic is sensitive, controversial, or provocative. But we need to have these tough conversations to understand and bridge differences and successfully collaborate in teams. Create opportunities for connections by using words that invite different perspectives and help people feel heard and valued.
In one-on-ones, demonstrate that it is safe to bring sensitive issues up with you. Say something like "To make real change, we have to understand each other. That takes talking and, importantly, listening to each other, to bridge our differences. Let's start there."
During your next team meetings, try demonstrating humility by accepting that your views may be incomplete and showing your willingness to hear other points of view. Here’s something you could say “It sounds like you have a strong point of view. Help me understand how you see this issue.”
Keep leading with intention. Use your words and actions to purposefully create different opportunities for dialogue. Rethink the "I tried once, and mindset -  it takes time to build trust and connection. Continue to assume positive intent.

###END of example

The format of the recommendation should be as follows:

You must generate a plan for each of the context area below:
${context.map((item,index)=>`- ${item} \n`)}

Each plan should be structured as follows:

# High level recommendation header including the factor name title. Do not include the word header. 1 sentence.
## Objective: header. Do not include the word header. 
Objective paragraph one which incorporates a high level description of what the recommendation is. 1 sentence
Objective paragraph two which describes why the recommendation is important using evidence from the data, and you must stress the main benefit or outcome for the manager by practicing the micro actions so that the leader feels that this is critical and urgent. When describing the benefit or usefulness to the leader, use second person (i.e., you). 2  sentences.
## Actions: header. Do not include the word header. 
“-” **Action Title:** Action 
“-” **Action Title:** Action 
“-” **Action Title:** Action 
A supplementary paragraph explaining why the recommendation is important using credible research, evidence, statistics, and/or best practice.
`
          }
        ]
        : [...history, { role: "user", content: userFeedback }];

    try {
      // Simulate streaming response
      const assistantResponse = await make_call(
        updatedHistory,
        (chunk) => {
          setAiMessage((prev) => prev + chunk);
        }
      );

      setLoading(false);
      setHistory((prevHistory) => [
        ...updatedHistory,
        { role: "assistant", content: assistantResponse.trim() }
      ]);
      setIsInitialCall(false);
      onComplete(assistantResponse.trim());
    } catch (error) {
      console.error("Error during AI call:", error);
      setLoading(false);
      // Handle error appropriately, e.g., show error message
    }
  };

  return (
    <AIContainer>
        {parseMarkdown(aiMessage)}
    </AIContainer>
  );
};

export default AIContentStep;
