import styled from "styled-components";
import React,{ useEffect, useState } from "react";



const Participation = () =>{
    return (
        <Container>
            <TitleText>
                Participation Slide
            </TitleText>
           

            <Title>
                Slide Preview
            </Title>
            <SlidePreview>
                <SlideTitle>
                    Participation Rate
                </SlideTitle>


                <SlideFooter>
                    innerlogic
                </SlideFooter>
            </SlidePreview>


        </Container>
    )
}

export default Participation;

const Container = styled.div`
    font-family: 'Raleway';
`

const TitleText = styled.div`

`

const Description = styled.div`

`

const SlidePreview = styled.div`
    width:100%;
    height:450px;
    padding:20px;
    border:1px solid #e0e0e0;
    border-radius:10px;
    margin-top:20px;
    position:relative;
`

const Title = styled.div`
    margin-top:20px;
    font-size: 20px;
`

const SlideTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-bottom:10px;
    font-family: 'Raleway';
    color:#333333;
`

const SlideFooter = styled.div`
    position:absolute;
    bottom:0;
    font-size: 12px;
    width:calc(100% - 40px);
    padding:10px 0px;
    border-top:1px solid #e0e0e0;
    display:flex;
`