import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import AICopilot from "app/audit/simple/components/AICopilot";
import Spark from "assets/images/UI/mdi_sparkles-outline.svg";

const removeFeedback = (string) => {
  const index = string.indexOf("\n\n**Feedback:**");
  if (index !== -1) {
    return string.substring(0, index);
  }
  return string;
};

const compileFeedback = (feedback) => {
  const compiledFeedback = [];

  feedback.forEach((item) => {
    const { question, response, follow_up } = item.response;
    const feedbackObj = {
      type: "Questionnaire",
      question,
      feedback: response,
      follow_up: follow_up
        ? {
            question: removeFeedback(follow_up?.question),
            feedback: follow_up?.response,
          }
        : null,
    };
    compiledFeedback.push(feedbackObj);
  });

  return compiledFeedback;
};

const Individual = ({
  responses,
  employees,
  assignees,
  selectedIndividual,
  setIndividual,
}) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedResponses, setSelectedResponses] = useState(null);
  const [copilot, setCopilot] = useState(false);
  const [copilotData, setCopilotData] = useState(null);

  useEffect(() => {
    if (selectedResponses) {
      const feedback = compileFeedback(
        selectedResponses.task_response?.task_list
      );
      setCopilotData(feedback);
    }
  }, [selectedResponses]);

  useEffect(() => {
    if (selectedIndividual) {
      setSelected(selectedIndividual);
      setIndividual(null);
    }
  }, [selectedIndividual]);

  useEffect(() => {
    if (employees?.employees?.length > 0 && responses?.length > 0) {
      const employeeIds = responses?.map((employee) => employee.employee);

      const options = employees?.employees
        ?.filter((emp) => {
          // filter by assignees list
          return employeeIds.includes(emp.id);
        })
        .map((employee) => {
          return {
            key: employee.id,
            text: `${employee.first_name} ${employee.last_name}`,
            value: employee.id,
          };
        });
      setOptions(options);
    }
  }, [employees, responses]);

  useEffect(() => {
    if (selected) {
      const selectedResponses = responses?.find(
        (response) => response.employee === selected
      );
      setSelectedResponses(selectedResponses);
    }
  }, [selected]);

  const copilotButton = (
    <AIButton onClick={() => setCopilot(true)}>
      Use the culture copilot <IMG2 src={Spark} />
    </AIButton>
  );

  return (
    <Container>
      {copilot && (
        <AICopilot
          isQuestionaire={true}
          feedbackData={copilotData}
          open={copilot}
          setOpen={setCopilot}
        />
      )}
      <Title>Individuals</Title>
      <Description>
        Select a person from your respondent list to see all their responses.
      </Description>
      <Dropdown
        placeholder="Select Person"
        fluid
        search
        selection
        value={selected}
        options={options}
        onChange={(e, { value }) => setSelected(value)}
        style={{ marginBottom: 30 }}
      />
      <TitleSection>
        <Title>Responses</Title>
        {copilotButton}
      </TitleSection>
      {!selected && <div>Select a person to view their responses</div>}
      {(selectedResponses &&
        selectedResponses?.task_response &&
        selectedResponses?.task_response?.task_list?.length > 0 &&
        selectedResponses?.task_response?.task_list?.map((question, i) => {
          const currentQuestion = question;
          return (
            <Response>
              <Name>
                Q{i + 1}. {currentQuestion?.question}
              </Name>
              <Answer>{currentQuestion?.response}</Answer>
              {currentQuestion?.follow_up && (
                <>
                  <AITag>AI Follow up</AITag>
                  <Follow>
                    {removeFeedback(currentQuestion?.follow_up?.question)}
                  </Follow>
                  <Answer>{currentQuestion?.follow_up?.response}</Answer>
                </>
              )}
            </Response>
          );
        })) || <div>No responses found</div>}
    </Container>
  );
};

export default Individual;

// Function to get initials from the name
const getInitials = (firstName, lastName) => {
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
};
const colors = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#e74c3c",
  "#e67e22",
  "#f1c40f",
  "#e84393",
  "#00cec9",
  "#0984e3",
  "#6c5ce7",
];

// Hash function to consistently map initials to a color
const hashStringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return colors[hash % colors.length];
};

const InitialsCircle = ({ firstName, lastName }) => {
  const initials = getInitials(firstName, lastName);
  const color = hashStringToColor(initials);

  return <Circle bgColor={color}>{initials}</Circle>;
};

const Container = styled.div`
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  max-width: 800px;
`;

const Description = styled.div``;


const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Response = styled.div`
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const Answer = styled.div`
  margin-bottom: 10px;
  line-height:1.6;
  white-space: pre-wrap;
`;

const IMG2 = styled.img`
  width: 20px;
  margin-left: 10px;
  margin-bottom: -5px;
`;

const Follow = styled.div`
  font-weight: bold;
  font-style: italic;
  margin-bottom: 10px;
`;

// Styled component for the circle
const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  font-weight: 600;
  margin-right: 10px;
`;

const QuestionField = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const AIButton = styled.div`
  background: #2d70e2;
  font-family: "Raleway", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background: #333;
  }
  min-width: 200px;
  max-height: 45px;
  margin-left: 20px;
`;


// Define the styled component for the Chip
const AITag = styled.div`
  color: blue;
  text-transform: uppercase;
  font-size: 10px;
  font-weight:bold;
  margin-top:20px;
`;