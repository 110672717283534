import React, { useRef, useEffect } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";


const FactorChart = ({ chartData, setChartURI }) => {
    const hiddenChartRef = useRef(null);
  
    useEffect(() => {
        let timeoutId;
      
        if (chartData && hiddenChartRef.current && hiddenChartRef.current?.chart) {
          // Delay to ensure the chart has rendered
          timeoutId = setTimeout(() => {
            hiddenChartRef.current.chart
              .dataURI({ width: 2400, height: 1600, scale: 1 })
              .then(({ imgURI }) => setChartURI(imgURI));
          }, 500); // Adjust the delay as needed
        }
      
        // Call handleUnmount on component unmount
        return handleUnmount;
      }, [chartData, setChartURI]);

    const handleUnmount = () => {
        if (hiddenChartRef.current) {
          hiddenChartRef.current = null; // Clear the ref on unmount
        }
        // Add any other unmount-related cleanup tasks here if needed
      };
      


      
  
    if (!chartData) return null;
  
    const seriesData = chartData.series.find((s) => s.name === "Average Score");
    if (!seriesData) return null;
  
    const scaledData = seriesData.data
      .map((value, index) => ({
        category: chartData.categories[index],
        value: value !== null ? Math.round(value * 10) : null,
      }))
      .filter((item) => item.value !== null)
      .sort((a, b) => b.value - a.value);
  
    const categories = scaledData.map((item) => item.category);
    const dataValues = scaledData.map((item) => item.value);
    const processedSeries = [{ name: seriesData.name, data: dataValues }];
  
    const options = {
      ...baseOptions,
      chart: { ...baseOptions.chart, height: 400 },
      xaxis: {
        categories,
        labels: { style: { fontFamily: "Raleway, sans-serif" } },
        max: 100,
      },
      yaxis: {
        title: { text: "Average Score" },
        labels: { style: { fontFamily: "Raleway, sans-serif" } },
      },
      tooltip: {
        ...baseOptions.tooltip,
        y: { formatter: (val) => val.toString() },
      },
      title: {
        text: "Overall Summary",
        align: "center",
        style: { fontFamily: "Raleway, sans-serif" },
      },
    };
  
    const hiddenChartOptions = {
      ...baseOptions,
      chart: {
        ...baseOptions.chart,
        id: "report-chart",
        width: 2400,
        height: 1600,
        animations: { enabled: false },
      },
      plotOptions: {
        bar: { horizontal: false, borderRadius: 10, barHeight: "70%" },
      },
      grid: {
        ...baseOptions.grid,
        padding: { top: 100, right: 100, bottom: 300, left: 100 },
      },
      xaxis: {
        type: "category",
        categories,
        title: {
          text: "Factors",
          offsetY: 200,
          style: { fontFamily: "Raleway, sans-serif", fontSize: 40 },
        },
        labels: {
          style: { fontFamily: "Raleway, sans-serif", fontSize: 20 },
          offsetY: 20,
          rotate: -45,
          maxHeight: 100,
          formatter: (val) =>
            val.length > 30 ? `${val.substring(0, 27)}...` : val,
        },
      },
      yaxis: {
        labels: {
          offsetX: 80,
          style: { fontFamily: "Raleway, sans-serif", fontSize: 20 },
          formatter: (value) => value.toFixed(0),
        },
        title: {
          text: "Average Score",
          offsetX: -30,
          style: { fontFamily: "Raleway, sans-serif", fontSize: 30 },
        },
        tickAmount: 5,
        max: 100
      },
      tooltip: {
        ...baseOptions.tooltip,
        y: { formatter: (value) => value.toFixed(1) },
      },
      title: {
        text: "Overall Summary",
        align: "center",
        style: { fontFamily: "Raleway, sans-serif", fontSize: 40 },
      },
    };
  
    return (
      <Container>
        {dataValues.length > 0 && (
          <>
            <Chart options={options} series={processedSeries} type="bar" height={400} />
            <HiddenChartContainer>
              <Chart
                ref={hiddenChartRef}
                options={hiddenChartOptions}
                series={processedSeries}
                type="bar"
                height={1600}
                width={2400}
              />
            </HiddenChartContainer>
          </>
        )}
      </Container>
    );
  };
  
export default FactorChart;

const Container = styled.div`
    margin-top: 20px;
    position: relative;
`

const HiddenChartContainer = styled.div`
  position: absolute;
  top: -10000px; /* Position it off-screen */
  left: -10000px;
  width: 2400px; /* Desired high resolution width */
  height: 1600px; /* Desired high resolution height */
  overflow: hidden; /* Prevent scrollbars */
`;


const baseOptions = {
    chart: {
      type: "bar",
      fontFamily: "Raleway, sans-serif",
      foreColor: "#7E7E7E",
      zoom: { enabled: false },
      toolbar: { show: false },
      background: "transparent",
    },
    colors: ["#2D70E2"],
    plotOptions: {
      bar: { horizontal: false, borderRadius: 5, barHeight: "70%" },
    },
    legend: { show: false },
    fill: { type: "solid" },
    grid: {
      xaxis: { lines: { show: true } },
      yaxis: { lines: { show: false } },
    },
    dataLabels: { enabled: false },
    tooltip: {
      enabled: true,
      style: { fontFamily: "Raleway, sans-serif" },
    },
  };