export const getColConfig = (categories, lang, translations) => {
  const primaryOptions = categories.categories.find(
    (f) => f.priority === "primary"
  );

  return primaryOptions.options.map((po) => {
    return {
      name: lang === "en" ? po.name : po.fr, 
      color: po.color,
      subfactors: po.subfactors,
      id: po.id,
    };
  });
};

export const getNSOTableData = (
  data,
  categories,
  structure,
  config,
  lang,
  translations
) => {
  const isFrench = lang === "fr";

  const tableData = structure.dimensions.map((d) => {
    const dimensionResponses = data?.summary?.filter(
      (r) => Number(r.dimension) === d.id - 1
    );

    const dimensionResponsesForOverall = data?.raw?.filter(
      (r) => Number(r.dimension) === d.id - 1
    );

    const tableGroups = categories.categories
      ?.find((ao) => ao.priority === "secondary")
      .options?.map((ao) => {
        return {
          title: isFrench
            ? ao?.fr.charAt(0).toUpperCase()
            : ao.name.charAt(0).toUpperCase(),
          id: ao.id,
        };
      });

    const dimensionData = d.factors
      .map((f) => {
        const factorResponses = dimensionResponses.filter(
          (r) => Number(r.factor) === f.id - 1
        );

        const factorResponsesForOverall = dimensionResponsesForOverall.filter(
          (r) => Number(r.factor) === f.id - 1
        );

        let n = 0;
        const factorAvg =
          factorResponsesForOverall.reduce((total, b) => {
            n += b.raw?.length;

            return total + b.raw?.reduce((acc, curr) => acc + curr, 0);
          }, 0) / n || null;

        const factorColumns = tableGroups.map((g) => {
          return {
            average: factorResponses.find((fr) => fr.secondary === g.id)
              ?.average,
            id: g.id,
          };
        });

        return {
          factor: isFrench ? f.fr : f.title,
          factorAverage: factorAvg,
          factorResponses: factorColumns,
        };
      })
      .sort((a, b) => b.factorAverage - a.factorAverage);

    let n = 0;
    let overallDimAverage = 0;

    dimensionData.forEach((dd) => {
      if (dd.factorAverage) {
        n++;
        overallDimAverage += dd.factorAverage;
      }
    });

    if (n > 0 && overallDimAverage > 0) {
      overallDimAverage = overallDimAverage / n;
    }

    return {
      dimension: isFrench ? d.fr : d.title,
      dimensionId: d.id,
      dimensionAvg: overallDimAverage,
      data: dimensionData,
    };
  });

  return tableData;
};

// Breakdown data charts.
export const getOTPTableData = (
  data,
  categories,
  structure,
  config,
  lang,
  translations
) => {
  const isFrench = lang === "fr";
  const tableData = structure.dimensions.map((d) => {
    const dimensionResponses = data?.filter(
      (r) => Number(r.dimension) === d.id - 1
    );

    let nationalAverage = {};
    dimensionResponses.forEach((dr) => {
      if (dr?.role) {
        if (!nationalAverage[dr.role]?.n) {
          nationalAverage[dr.role] = {
            n: dr?.n || 0,
            sum: dr?.average ? dr?.average * dr?.n : 0,
          };
        } else {
          nationalAverage[dr.role].n += dr?.n || 0;
          nationalAverage[dr.role].sum += dr?.average ? dr?.average * dr?.n : 0;
        }
      }
    });

    const tableGroups = categories.categories
      ?.find((ao) => ao.priority === "secondary")
      .options?.map((ao) => {
        return {
          title: ao.name.charAt(0).toUpperCase(),
          id: ao.id,
        };
      });
    // loop through config to get categorized data
    const dimensionInfo = config.map((ci) => {
      const primaryLabel = ci.name;
      let primaryResponses = [];
      let sportAverage = {};
      dimensionResponses.forEach((dr) => {
        const matchingResponses = dr.primaryData.filter(
          (fpd) => ci.id === fpd.group
        );
        primaryResponses.push({
          responses: matchingResponses,
          role: dr.role,
          primary: primaryLabel,
        });
      });

      return {
        primary: primaryLabel,
        responses: primaryResponses,
        id: ci.id,
      };
    });

    let nationalSum = 0;
    let nationalNValue = 0;
    const nationalAvgRows = tableGroups.map((tg) => {
      const currentGroup = nationalAverage[tg.id];
      const roleAvg = currentGroup?.sum ? currentGroup.sum / currentGroup.n : 0;
      nationalSum += nationalAverage[tg.id]?.sum || 0;
      nationalNValue += nationalAverage[tg.id]?.n || 0;
      return {
        id: tg.id,
        average: roleAvg,
      };
    });

    const nationalAvg = nationalSum / nationalNValue;

    const nationalAverageRow = {
      factor: getTranslatedText("nationalAverage", lang, translations),
      factorAverage: nationalAvg,
      factorResponses: nationalAvgRows,
      rowId: 0,
    };

    const dimensionData = config.map((ci) => {
      // get list of all responses related to current category.
      const ciResponses = dimensionInfo
        .filter((di) => di.id === ci.id)
        .map((di) => {
          const diResponses = di.responses.map((dr) => dr.responses);
          return diResponses.flat();
        })
        .flat();
      const ciAvg =
        ciResponses.reduce((total, b) => {
          return (total += b.response ? Number(b.response) : 0);
        }, 0) / ciResponses.length;

      const rowCols = tableGroups.map((tg) => {
        const ciResponses = dimensionInfo
          .filter((di) => di.id === ci.id)
          .map((di) => {
            const diResponses = di.responses
              .filter((dir) => dir.role === tg.id)
              .map((dr) => dr.responses);
            return diResponses.flat();
          })
          .flat();
        const roleAvg =
          ciResponses.reduce((total, b) => {
            return (total += b.response ? Number(b.response) : 0);
          }, 0) / ciResponses.length;

        return {
          id: tg.id,
          average: roleAvg,
        };
      });

      return {
        factor: ci.name,
        factorAverage: ciAvg,
        factorResponses: rowCols,
        rowId: ci.id,
      };
    });

    dimensionData.unshift(nationalAverageRow);

    return {
      dimension: isFrench ? d.fr : d.title,
      dimensionId: d.id,
      dimensionAvg: null,
      data: dimensionData,
    };
  });

  return tableData;
};

export const getSecondaryLabel = (id, categories) => {
  const secondaryItems = categories.categories.find(
    (c) => c.priority === "secondary"
  );
  const label = secondaryItems?.options?.find((o) => o.id === id);

  return label;
};

export const buildRankingTables = (
  data,
  categories,
  question,
  dimension,
  type,
  isFrench,
  translations
) => {
  const lang = isFrench ? "fr" : "en";
  const anchorName = categories.categories?.find(
    (ao) => ao.priority === "secondary"
  );
  let colWidth;
  const title =
    type === "highest"
      ? getTranslatedText("highestLWC", lang, translations)
      : getTranslatedText("lowestLWC", lang, translations);

  const tableTemplate = categories.categories
    ?.find((ao) => ao.priority === "secondary")
    .options.map((anchor) => {
      colWidth = 39 / (anchorName.options.length + 1);
      return {
        title: isFrench
          ? anchor?.fr.charAt(0).toUpperCase()
          : anchor.name.charAt(0).toUpperCase(),
        id: anchor.id,
        width: `${colWidth}%`,
      };
    });

  tableTemplate.unshift({ title: "AV", width: `${colWidth}%` });
  tableTemplate.unshift({
    title: getTranslatedText("domain", lang, translations),
    width: "25%",
  });
  tableTemplate.unshift({ title, width: "46%" });

  const dimensionData = [];
  data.forEach((d) => {
    if (d.dimension === dimension) {
      dimensionData.push(d);
    }
  });

  const dimensionQuestions = [];
  const dimensionTalley = [];

  dimensionData.forEach((dd) => {
    if (
      !dimensionTalley.find(
        (dt) => dt.question === dd.question && dt.section === dd.section
      )
    ) {
      const factorLabel = getFactorLabel(dd, question, isFrench);
      dimensionTalley.push(dd);
      dimensionQuestions.push({
        question: dd.question,
        dimension: dd.dimension,
        section: dd.section,
        factor: factorLabel,
      });
    }
  });

  const dimensionAverages = dimensionQuestions
    .map((dq) => {
      let reverse = false;
      const questionResponses = data
        .filter(
          (dd) =>
            dd.question === dq.question &&
            dd.dimension === dq.dimension &&
            dd.section === dq.section
        )
        .filter((dd) => !isQuestionBinary(dd, question));

      questionResponses.forEach((r) => {
        if (fetchReverseOption(r, question)) {
          reverse = true;
        }
      });

      let n = 0;

      const questionAvg =
        questionResponses.reduce((total, b) => {
          n += b.n;
          return total + b.average * b.n;
        }, 0) / n || 0;

      return {
        question: dq,
        average: questionAvg,
        groups: questionResponses,
        reverse: reverse,
      };
    })
    .sort((a, b) => {
      let bAverage = b.average * 10;
      let aAverage = a.average * 10;

      if (a.reverse) {
        aAverage = 100 - aAverage;
      }

      if (b.reverse) {
        bAverage = 100 - bAverage;
      }

      return bAverage - aAverage;
    });

  const rankData = dimensionAverages
    .filter((item) => item?.groups?.length > 0)
    .filter((da) => {
      let n = 0;
      const avg =
        (da.groups.reduce((total, b) => {
          n += b.n;
          return total + b.average * b.n;
        }, 0) /
          n) *
          10 || 0;

      const average = da.reverse ? 100 - (avg * 10) / 10 : avg / 10;
      return average < 10; // Should be whitelabel config value.
    })
    .map((item) => {
      let n = 0;
      const avg =
        item.groups.reduce((total, b) => {
          n += b.n;

          return total + b.average * b.n;
        }, 0) / n || 0;
      const average = (avg * 10) / 10;
      const averagePrefix = item.reverse ? "**" : "";

      const tableData = tableTemplate.map((t) => {
        if (t.title === getTranslatedText("domain", lang, translations)) {
          return `${item.question.factor}`;
        }
        if (t.title === "AV") {
          return `${average.toFixed(1)}${averagePrefix}`;
        }
        if (!t.id) {
          return `${fetchQuestion(item.groups[0], question, isFrench)}`;
        }

        const groupData = item.groups.filter((g) => g.role === t.id);

        const itemAverage =
          (groupData?.length > 0 &&
            groupData.reduce((total, b) => {
              return total + b.average;
            }, 0)) ||
          null;

        const group =
          (groupData.length > 0 &&
            (!isNaN(itemAverage) && itemAverage / groupData.length) * 10) ||
          null;

        if (groupData?.length === 0) {
          return -1;
        }
        const groupAverage = group / 10;
        return `${groupAverage.toFixed(1)}`;
      });

      return tableData;
    });

  const rankedData =
    type === "highest"
      ? rankData.slice(0, 5)
      : rankData.reverse().slice(0, 5).reverse();

  return rankedData;
};

export const buildOverallRankingTables = (
  data,
  categories,
  question,
  type,
  isFrench,
  translations
) => {
  const lang = isFrench ? "fr" : "en";
  const anchorName = categories.categories?.find(
    (ao) => ao.priority === "secondary"
  );

  let colWidth;
  const title =
    type === "highest"
      ? getTranslatedText("highestLWC", lang, translations)
      : getTranslatedText("lowestLWC", lang, translations);

  const tableTemplate = categories.categories
    ?.find((ao) => ao.priority === "secondary")
    .options.map((anchor) => {
      colWidth = 39 / (anchorName.options.length + 1);
      return {
        title: isFrench
          ? anchor?.fr.charAt(0).toUpperCase()
          : anchor.name.charAt(0).toUpperCase(),
        id: anchor.id,
        width: `${colWidth}%`,
      };
    });

  tableTemplate.unshift({ title: "AV", width: `${colWidth}%` });
  tableTemplate.unshift({
    title: getTranslatedText("domain", lang, translations),
    width: "25%",
  });
  tableTemplate.unshift({ title, width: "46%" });

  const dimensionData = [];
  data.forEach((d) => {
    dimensionData.push(d);
  });

  const dimensionQuestions = [];
  const dimensionTalley = [];

  dimensionData.forEach((dd) => {
    if (
      !dimensionTalley.find(
        (dt) =>
          dt.question === dd.question &&
          dt.section === dd.section &&
          dt.dimension === dd.dimension
      )
    ) {
      const factorLabel = getFactorLabel(dd, question, isFrench);
      dimensionTalley.push(dd);
      dimensionQuestions.push({
        question: dd.question,
        dimension: dd.dimension,
        section: dd.section,
        factor: factorLabel,
      });
    }
  });

  const dimensionAverages = dimensionQuestions
    .map((dq) => {
      let reverse = false;
      const questionResponses = data
        .filter(
          (dd) =>
            dd.question === dq.question &&
            dd.dimension === dq.dimension &&
            dd.section === dq.section
        )
        .filter((dd) => !isQuestionBinary(dd, question));

      questionResponses.forEach((r) => {
        if (fetchReverseOption(r, question)) {
          reverse = true;
        }
      });

      let n = 0;

      const questionAvg =
        questionResponses.reduce((total, b) => {
          n += b.n;
          return total + b.average * b.n;
        }, 0) / n || 0;

      return {
        question: dq,
        average: questionAvg,
        groups: questionResponses,
        reverse: reverse,
      };
    })
    .sort((a, b) => {
      let bAverage = b.average * 10;
      let aAverage = a.average * 10;

      if (a.reverse) {
        aAverage = 100 - aAverage;
      }

      if (b.reverse) {
        bAverage = 100 - bAverage;
      }

      return bAverage - aAverage;
    });

  const rankData = dimensionAverages
    .filter((item) => item?.groups?.length > 0)
    .filter((item) => {
      let n = 0;
      const avg =
        (item.groups.reduce((total, b) => {
          n += b.n;
          return total + b.average * b.n;
        }, 0) /
          n) *
          10 || 0;
      const average = item.reverse ? 100 - (avg * 10) / 10 : avg / 10;
      return average < 10; // Should be whitelabel config value.
    })
    .map((item) => {
      let n = 0;
      const avg =
        item.groups.reduce((total, b) => {
          n += b.n;

          return total + b.average * b.n;
        }, 0) / n || 0;
      const average = (avg * 10) / 10;
      const averagePrefix = item.reverse ? "**" : "";

      const tableData = tableTemplate.map((t) => {
        if (t.title === getTranslatedText("domain", lang, translations)) {
          return `${item.question.factor}`;
        }
        if (t.title === "AV") {
          return `${average.toFixed(1)}${averagePrefix}`;
        }
        if (!t.id) {
          return `${fetchQuestion(item.groups[0], question, isFrench)}`;
        }

        const groupData = item.groups.filter((g) => g.role === t.id);

        const group =
          (groupData.reduce((total, b) => {
            return total + b.average;
          }, 0) /
            groupData.length) *
            10 || 0;
        const groupAverage = group / 10;

        return `${groupAverage.toFixed(1)}`;
      });

      return tableData;
    });

  const rankedData =
    type === "highest"
      ? rankData.slice(0, 5)
      : rankData.reverse().slice(0, 5).reverse();

  return rankedData;
};

export const getOptions = (
  dimension = 0,
  calculatedData,
  questionStructure
) => {
  let averages = calculatedData.summary?.filter(
    (factorSummary) => factorSummary.dimension === dimension.toString()
  );

  let factors = questionStructure.dimensions[dimension]?.factors?.map(
    (factor) => {
      return factor.title;
    }
  );

  // TODO: When additional data is needed, update this map to return
  // other properties, such as Norm & Change from prev
  let factorOverview = factors?.map((item, i) => {
    if (i + 1 > averages.length) {
      return null;
    }
    const factorAverage = averages
      .filter((avg) => Number(avg.factor) === i)
      .map((avgItem) => avgItem.average);

    return {
      factor: item,
      average: getAverage(factorAverage),
      id: i,
    };
  });

  return factorOverview;
};

const getAverage = (data) => {
  let total = 0;
  let n = 0;
  data.map((i) => {
    total += i;
    n += 1;
  });

  return total / n;
};

const fetchQuestion = (q, question, isFrench) => {
  const questionFound =
    question?.dimensions[q.dimension].factors[Number(q.section)].questions[
      Number(q.question)
    ];

  return isFrench ? questionFound?.fr : questionFound?.q;
};

const isQuestionBinary = (q, question) => {
  return question.dimensions[q.dimension].factors[Number(q.section)].questions[
    Number(q.question)
  ]?.binary;
};

const fetchReverseOption = (q, question) => {
  return question.dimensions[q.dimension].factors[Number(q.section)].questions[
    Number(q.question)
  ]?.reverse;
};

const getFactorLabel = (q, question, isFrench) => {
  return isFrench
    ? question.dimensions[q.dimension].factors[Number(q.section)]?.fr
    : question.dimensions[q.dimension].factors[Number(q.section)].title;
};

export const getTranslatedText = (
  textId,
  language,
  translationObj,
  subtext = false
) => {
  const text = translationObj[textId];
  if (subtext) {
    return text.subtext[language];
  }
  if (text) {
    return text[language];
  }
  return textId;
};

export const navigationMap = [
  {
    page: 1,
    title: "Physical health and well-being",
    fr: "Santé physique et bien-être",
  },
  {
    page: 2,
    title: "Physical safety and safe sport",
    fr: "Sécurité physique et sport sécuritaire",
  },
  { page: 3, title: "Self-determination", fr: "Autodétermination" },
  {
    page: 4,
    title: "Mental health and well-being",
    fr: "Santé mentale et bien-être",
  },
  { page: 5, title: "Psychological safety", fr: "Sécurité psychologique" },
  {
    page: 11,
    title: "daily training environment",
    fr: "Environnement d’entraînement quotidien",
  },
  { page: 12, title: "coaching", fr: "Entraînement" },
  { page: 13, title: "leadership", fr: "leadership" },
  { page: 14, title: "pathways and profiles", fr: "Cheminements et profils" },
  {
    page: 15,
    title: "sport science and sport medicine",
    fr: "Sciences du sport et médecine du sport",
  },
  {
    page: 16,
    title: "athletes and international results",
    fr: "Athlètes et résultats internationaux",
  },
];