import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import IntroScreen from "./IntroScreen";
import Templates from "./Templates";
import SelectAnchor from "./SelectAnchor";
import GenerateChart from "./GenerateChart";

const Presentation = () => {
    const [step, setStep] = useState(0);
    const [selectedTemplate, setSelectedTemplate] = useState();

    const selectedFilters = useSelector(
        (state) => state.audit?.new_filters,
        shallowEqual
      );

    const filtered_data = useSelector(
        (state) => state.audit?.filtered_data,
        shallowEqual
      );

    const core_data = useSelector(
        (state) => state.audit?.core_data,
        shallowEqual
      );

    const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);

    

    const renderStep = () => {
        switch(step){
            case 0:
                return <IntroScreen start={()=>setStep(1)}/>
            case 1:
                return <Templates 
                        next={()=>setStep(2)} 
                        selected={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}/>

            case 2:
                return <SelectAnchor next={()=>setStep(3)}/>

            case 3:
                return <GenerateChart
                        data={filtered_data}
                        filters={selectedFilters}
                        core_data={core_data}
                        anchor={anchor}/>

            default:
                return <IntroScreen start={()=>setStep(1)}/>
        }
    }

    return (
        <Container>
            <Content>
            {renderStep()}
            </Content>
            
        </Container>
    )
}


export default Presentation;




const Container = styled.div`
    padding:20px 10px;
    font-family: 'Raleway';
    display:flex;
    justify-content:center;
    width:100%;
`


const Content = styled.div`
    width:100%;
`