import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReportType from "./ReportType";
import TemplateBuilder from "./TeamplteList/index";
import Menu from "./Menu";
import Empty from "./Empty";

const Templates = ({ next, selected }) => {
  const [slides, setSlides] = useState([]); // Each slide is { template: '...', data: { ... } }
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedSlideData, setSelectedSlideData] = useState(null);
  const [loading, setLoading] = useState(false);

  const addSlide = (template) => {
    const newSlide = { template, data: {} };
    setSlides([...slides, newSlide]);
    setSelectedSlideIndex(slides.length);
  };

  const updateSlideData = (data) => {
    setSlides((prevSlides) => {
      const updatedSlides = [...prevSlides];
      updatedSlides[selectedSlideIndex] = {
        ...updatedSlides[selectedSlideIndex],
        data,
      };
      return updatedSlides;
    });
  };

  useEffect(() => {
    if (selectedSlideIndex !== null) {
      const selectedSlide = slides[selectedSlideIndex];
      setSelectedTemplate(selectedSlide.template);
      setSelectedSlideData(selectedSlide.data)
    }
  }, [selectedSlideIndex, slides]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
        setLoading(false);
    }, 500);
}, [selectedSlideIndex]);


  return (
    <Container>
        <Top>
            <BlueTitle>
                AI Toolkit
            </BlueTitle>

            <BigTitle>
                Presentation Builder
            </BigTitle>
        </Top>

        <Content>
            <SlideOptions>
            <Menu onSelect={(t) => addSlide(t)} />

            {slides.map((slide, index) => (
            <Option
                key={index}
                onClick={() => setSelectedSlideIndex(index)}
                selected={selectedSlideIndex === index}
            >
                {slide.template}

                <FactorTitle>
                {slide.data?.title}
                </FactorTitle>
            </Option>
            ))}
        </SlideOptions>

        <SelectedTemplate>
            {selectedTemplate ? (
            <TemplateBuilder
                selected={selectedTemplate}
                slideData={selectedSlideData}
                updateSlideData={updateSlideData}
                loading={loading}
            />
            ) : (
            <Empty />
            )}
        </SelectedTemplate>
        </Content>
     
    </Container>
  );
};

export default Templates;


const Container = styled.div`
    margin-top:-10px;
`

const Content = styled.div`
    display:flex;
    width:100%;
`
const SlideOptions = styled.div`
    width:250px;
    height:calc(100vh - 100px);
    display:flex;
    flex-direction:column;
    padding:10px;
    border-right:1px solid #ccc;
`

const Option = styled.div`
    width:100%;
    padding:10px;
    height:75px;
    border:${props=>props.selected?'2px solid #2D70E2':'1px solid #ccc'};
    border-radius:10px;
    cursor:pointer;
    &:hover{
        background-color:#F8FAFF;
    }
    font-size: 12px;
    margin-bottom:10px;


    color:${props=>props.selected?'#2D70E2':'#666D79'};
    font-weight:${props=>props.selected?'600':'400'};
    background-color:${props=>props.selected?'#F8FAFF':'#fff'};

`

const SelectedTemplate = styled.div`
    width:calc(100% - 250px);
    padding:20px;
`

const Top = styled.div`
    margin-bottom:40px;
`

const BlueTitle = styled.div`
    font-size: 12px;
    color: #2d70e2;
    font-weight:600;
    margin-bottom:5px;
`

const BigTitle = styled.div`
    color:#2A3039;
    font-size: 32px;
    font-weight: 700;
`

const FactorTitle = styled.div`
    font-size: 12px;
    color: #666D79;
    margin-top:5px;
    font-weight:400;
`