// CompletedStep.jsx
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Segment, Image, Header } from 'semantic-ui-react';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CompletedContainer = styled.div`
  animation: ${fadeIn} 0.5s ease-in;
  text-align: center;
`;

const CompletedStep = ({ chartURI, aiContent }) => {
  return (
    <CompletedContainer>
      Your presentation is ready!

      <ButtonArea>
        <Button>Download my presentation</Button>
      </ButtonArea>
    </CompletedContainer>
  );
};

export default CompletedStep;





const ButtonArea = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;


const Button = styled.div`
  padding: 10px 20px;
  background-color: #2d70e2;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  &:hover {
    background-color: #1c4db8;
  }

`;