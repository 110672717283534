import React, { useEffect, useState,useRef } from "react";
import { calculate_summary_chart } from 'app/audit/simple/factor/calculations';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import Anchor from "../Anchor";
import StarImg from 'assets/images/UI/star.svg';
import Arrow from 'assets/images/UI/arrow_blue.svg';
import FactorChart from './FactorChart';
import factor_content from 'app/audit/simple/factor/factor_content.json';
import styled from 'styled-components';

import { SET_DATA_ACNHOR } from "constants/actions";


const Factor = ({ slideData, updateSlideData,loading }) => {
    const dispatch = useDispatch();
  
    // Local state
    const [selectedFactor, setSelectedFactor] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [factorDropdown, setFactorDropdown] = useState([]);
    const [chartURI, setChartURI] = useState(() => slideData?.chartURI || null);
  
    // Ref to prevent cyclical updates
    const skipAnchorEffect = useRef(false);
  
    // Redux state
    const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);
    const data = useSelector((state) => state.audit?.filtered_data, shallowEqual);
    const lastData = useSelector((state) => state.audit?.filtered_last_data, shallowEqual);
    const coreData = useSelector((state) => state.audit?.core_data, shallowEqual);
    const rule = 2;
  
    // Load data when a slide with existing data is selected
    useEffect(() => {
      if (slideData && slideData.selectedFactor) {
        if (slideData.selectedFactor?.title !== selectedFactor?.title) {
          setSelectedFactor(slideData.selectedFactor);
        }
      } else {
        setSelectedFactor(null);
      }
      if (slideData && slideData.anchor && slideData.anchor !== anchor) {
        skipAnchorEffect.current = true; // Set the flag
        dispatch({
          type: SET_DATA_ACNHOR, // Replace with your actual action type
          payload: slideData.anchor,
        });
      }
    }, [slideData]);
  
    // Update factor dropdown when coreData changes
    useEffect(() => {
      if (coreData) {
        const options = coreData.questions.dimensions.flatMap((dimension, i) =>
          dimension.factors.map((factor) => ({
            key: factor.id + factor.title,
            text: factor.title,
            value: { ...factor, dimension: i, type: 'factor' },
          }))
        );
  
        setFactorDropdown(options);
      }
    }, [coreData]);
  
    // Set default selectedFactor when factorDropdown changes
    useEffect(() => {
      if (factorDropdown.length > 0 && !selectedFactor) {
        setSelectedFactor(factorDropdown[0].value);
        console.log('Default selectedFactor set:', factorDropdown[0].value);
      }
    }, [factorDropdown, selectedFactor]);
  
    // Update chartData when selectedFactor or data changes
    useEffect(() => {
      if (selectedFactor && coreData) {
        const calculatedChartData = calculate_summary_chart(
          data,
          lastData,
          anchor,
          selectedFactor,
          coreData,
          rule
        );
        setChartData(calculatedChartData);
      }
    }, [selectedFactor, data, lastData, anchor, coreData]);
  
    // Update slideData when selectedFactor, anchor, chartData, or chartURI changes
    useEffect(() => {
      if (chartData && selectedFactor && anchor) {
        const content = factor_content[selectedFactor.title];
        const newSlideData = {
          ...slideData,
          title: selectedFactor.title,
          overallScore: (chartData.overallStats.average * 10).toFixed(0) + '%',
          change: getChange(),
          content: content?.content?.p || '',
          chartURI,
          slide_template_id: '1ru6X5AWfAL1Z9F86RME1h6NPDJ1ZuFHrWUnIugCALjg',
          anchor,
          selectedFactor,
        };
  
        // Only update if newSlideData is different from slideData
        if (JSON.stringify(newSlideData) !== JSON.stringify(slideData)) {
          updateSlideData(newSlideData);
        }
      }
      // Exclude slideData and updateSlideData from dependencies to prevent loops
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFactor, chartURI]);
  
    // Handle anchor changes from user input
    useEffect(() => {
      if (skipAnchorEffect.current) {
        skipAnchorEffect.current = false; // Reset the flag
        return; // Skip updating slideData
      }
      if (anchor && (!slideData || slideData.anchor !== anchor)) {
        console.log('updating', anchor, slideData);
        const newSlideData = {
          ...slideData,
          anchor,
        };
        updateSlideData(newSlideData);
      }
    }, [anchor]);
  
    const getChange = () => {
      if (chartData?.lastOverallStats?.average) {
        const change = chartData.overallStats.average - chartData.lastOverallStats.average;
        return (change * 10).toFixed(1) + '%';
      }
      return '--';
    };
  
    const findContent = () => {
      if (selectedFactor) {
        const content = factor_content[selectedFactor.title];
        return content ? <p>{content.content.p}</p> : '';
      }
      return '';
    };
  
    const handleFactorChange = (e, { value }) => {
      setSelectedFactor(value);
    };
  
    const handleSetChartURI = (uri) => {
      setChartURI(uri);
    };

    if(loading){
      return <div>Loading...</div>
    }

  
    return (
      <Container>
        <T1>Factor Analysis Slide</T1>
        <SubTitle>Slide Options</SubTitle>
        <OptionArea>
          <div style={{ marginRight: 20 }}>
            <OptionTitle>Select a Factor</OptionTitle>
            <Dropdown
              placeholder='Select Factor'
              selection
              options={factorDropdown}
              onChange={handleFactorChange}
              value={selectedFactor}
            />
          </div>
          <div>
            <OptionTitle>Category</OptionTitle>
            <Anchor />
          </div>
        </OptionArea>
        <SlidePreview>
          <SlideTitle>{selectedFactor?.title}</SlideTitle>
          <Content>
            <div style={{ display: 'flex' }}>
              <ScoreArea>
                <GreenBlock>
                  <ScoreImg src={StarImg} />
                </GreenBlock>
                <div>
                  <Score>{(chartData?.overallStats.average * 10).toFixed(0)}%</Score>
                  <ScoreTitle>Overall Score</ScoreTitle>
                </div>
              </ScoreArea>
              <ScoreArea>
                <BlueBlock>
                  <ScoreImg2 src={Arrow} />
                </BlueBlock>
                <div>
                  <Score>{getChange()}</Score>
                  <ScoreTitle>Change</ScoreTitle>
                </div>
              </ScoreArea>
            </div>
          </Content>
          <Description>{findContent()}</Description>
          <FactorChart setChartURI={handleSetChartURI} chartData={chartData} />
          <SlideFooter>innerlogic</SlideFooter>
        </SlidePreview>
      </Container>
    );
  };
  
  export default Factor;


const Container = styled.div`

`

const SlidePreview = styled.div`
    width:100%;
    height:650px;
    padding:20px;
    border:1px solid #e0e0e0;
    border-radius:10px;
    margin-top:20px;
    position:relative;
`

const Title = styled.div`
    margin-top:20px;
    font-size: 16px;
`

const SlideTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-bottom:40px;
    font-family: 'Raleway';
    color:#333333;
`

const SlideFooter = styled.div`
    position:absolute;
    bottom:0;
    font-size: 12px;
    width:calc(100% - 40px);
    padding:10px 0px;
    border-top:1px solid #e0e0e0;
    display:flex;
`


const Content = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
`

const ScoreArea = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    margin-right:50px;
`

const ScoreTitle = styled.div`
    color:#666D79;
    font-size:12px;

`

const Score = styled.div`
color:#2A3039;
font-size:14px;
font-weight:600;
`

const ScoreImg = styled.img`
    width:20px;
`

const ScoreImg2 = styled.img`
    width:30px;
`

const GreenBlock = styled.div`
    background-color:#EAFBF7;
    height:40px;
    width:40px;
    border-radius:8px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right:10px;
`

const BlueBlock = styled.div`
     background-color:#EBF1FD;
    height:40px;
    width:40px;
    border-radius:8px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right:10px;
`

const Description = styled.div`
    margin-top:20px;
`


const OptionArea = styled.div`
    margin-bottom:20px;
    display:flex;
    padding:20px;
    background-color:#f9fbfd;
    width:100%;
`

const OptionTitle = styled.div`
    font-size:12px;
    font-weight:500;
    margin-bottom:5px;
`
const SubTitle = styled.div`
    font-size: 14px;
    margin-top:20px;
    margin-bottom:5px;
    font-weight:600;
`

const T1 = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom:10px;
    color:#2A3039;
`