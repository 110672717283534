import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Popup } from "semantic-ui-react";
import { format } from "date-fns";
import {
  deleteIcon,
  downloadIcon,
  caretDown,
  caretUp,
  viewIcon,
} from "./Icons";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_GET_DEBRIEF_PDF_REPORT_URL,
  CLEAR_DEBRIEF_PDF_REPORT_URL,
} from "constants/actions";
import { Pagination } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

const statusColors = {
  New: {
    container: "#FFFAF1",
    text: "#FEA711",
  },
  Error: {
    container: "#FEF3F4",
    text: "#F15062",
  },
  Completed: {
    container: "#F1FAF9",
    text: "#00B67A",
  },
  Pending: {
    container: "#FFFAF1",
    text: "#FEA711",
  },
};

// function to map specific names to the report type.
const mapReportType = (type) => {
  switch (type) {
    case "version 2":
      return "Culture Summary";
    default:
      return type;
  }
};

const templateMappings = {
  8: {
    name: "ROI Report",
    getData: (data) => {
      const { params, additional_info } = data;
      const { outcome_name } = params;
      const jsonData = additional_info?.[0]?.json_data;
      return {
        categoryName: outcome_name,
        parentCategory: outcome_name,
        markdownReport: !!jsonData,
      };
    },
    getViewUrl: (id) => `/leader/audit/reports/roi/${id}`, // ROI Report URL
  },
  9: {
    name: "Change Report",
    getData: (data, categories) => {
      const { params, additional_info } = data;
      const jsonData = additional_info?.[0]?.json_data;

      const { parent_id, orgName } = params;
      const parent = parent_id;
      const parentCategory = categories?.[0]?.categories?.find(
        (cat) => cat.id === parent
      );
      return {
        categoryName: `${parentCategory?.name || orgName}`,
        parentCategory: parentCategory?.name,
        markdownReport: !!jsonData,
      };
    },
    getViewUrl: (id) => `/leader/audit/reports/change/${id}`, // Change Report URL
  },
  // Add more template mappings as needed
};

// Function to map the data so that it can be used in a table.
// We need to get the following, id, primary_category, parent_category, status_text
const mapTableData = (data, categories, templates) => {
  const {
    params,
    created_at,
    status_text,
    id,
    tracking_code,
    template_id,
    download_files,
  } = data;

  const templateMapping = templateMappings[template_id];
  if (templateMapping) {
    const templateData = templateMapping.getData(data, categories);
    return {
      id,
      ...templateData,
      status: status_text,
      created: created_at,
      tracking_code,
      templateName: templateMapping.name,
      downloads:
        template_id !== 9 &&
        download_files?.length > 0 &&
        download_files?.map((file) =>
          parseFileName(file, categories?.[0]?.categories)
        ),
    };
  }

  // Default mapping logic if template_id doesn't match any in templateMappings
  const { parent_children, parent_id, orgName } = params;
  const primary = parent_children[0];
  const parent = parent_id;
  const parentCategory = categories?.[0]?.categories?.find(
    (cat) => cat.id === parent
  );

  const template = templates?.find((temp) => temp.id === template_id);
  return {
    id,
    categoryName: `${parentCategory?.name || orgName}`,
    parentCategory: parentCategory?.name,
    status: status_text,
    created: created_at,
    tracking_code,
    templateName: template?.name,
    downloads:
      (download_files?.length > 0 &&
        download_files?.map((file) =>
          parseFileName(file, categories?.[0]?.categories)
        )) ||
      [],
  };
};

const parseFileName = (downloadObj, categories) => {
  const fileName = downloadObj?.file_location?.split("/").pop();
  const category = categories
    ?.find((cat) => cat?.id === downloadObj?.parent)
    ?.options?.find((o) => o?.id === downloadObj?.category)?.name;
  const parent = categories?.find(
    (cat) => cat.id === downloadObj?.parent
  )?.name;
  const primary = categories
    .find((cat) => cat.priority?.toLowerCase() === "primary")
    ?.options?.find((o) => o?.id === downloadObj?.primary)?.name;
  return {
    category,
    fileName,
    share_id: downloadObj?.share_id,
    parent,
    primary,
    hasData:
      downloadObj?.data_found !== undefined ? downloadObj?.data_found : true,
  };
};

const Table = ({
  data,
  categories,
  templates,
  setDeleteId,
  loading,
  setDeleteItem,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [hovered, setHovered] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pagedData, setPagedData] = useState([]);
  const itemsPerPage = 10;

  const { pdfReports } = useSelector(
    (state) => ({
      pdfReports: state.debrief_pdf_report,
    }),
    shallowEqual
  );

  const handleDeleteGroup = (id) => {
    setDeleteId(id);
  };

  const handleDeleteItem = (item) => {
    setDeleteItem(item);
  };

  const toggleRow = (id) => {
    const isRowCurrentlyExpanded = expandedRows.includes(id);
    const newExpandedRows = isRowCurrentlyExpanded
      ? expandedRows.filter((rowId) => rowId !== id)
      : [...expandedRows, id];
    setExpandedRows(newExpandedRows);
  };

  const handleDownload = (fileName) => {
    dispatch({
      type: SG_GET_DEBRIEF_PDF_REPORT_URL,
      payload: fileName,
    });
  };

  const handleView = (item) => {
    const templateMapping = templateMappings[item.template_id];
    if (templateMapping && templateMapping.getViewUrl) {
      navigate(templateMapping.getViewUrl(item.id));
    } else {
      console.warn(`No view URL mapping for template_id: ${item.template_id}`);
    }
  };

  useEffect(() => {
    if (data?.length > 0) {
      setTotalPages(Math.ceil(data.length / itemsPerPage));
      const start = (activePage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      setPagedData(data.slice(start, end));
    }
  }, [data, activePage]);

  useEffect(() => {
    if (pdfReports?.download_url) {
      window.open(pdfReports?.download_url?.url, "_blank");
      dispatch({
        type: CLEAR_DEBRIEF_PDF_REPORT_URL,
      });
    }
  }, [pdfReports?.download_url]);

  return (
    <Container>
      <Header>
        <TH width={20}>Category</TH>
        <TH width={25}>Report Type</TH>
        <TH width={15}>Status</TH>
        <TH width={20}>Date</TH>
        <TH width={10}>Actions</TH>
      </Header>
      {!loading &&
        pagedData?.length > 0 &&
        pagedData
          ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          ?.map((item) => {
            const mappedData = mapTableData(
              item,
              categories,
              templates?.response
            );

            const isExpanded = expandedRows.includes(item.id);
            return (
              <React.Fragment key={item.id}>
                <Row
                  onMouseOver={() => {
                    setHovered(item.id);
                  }}
                  onMouseOut={() => setHovered(null)}
                  rowIsHovered={hovered === item.id}
                >
                  <TD width={20}>
                    <MultipleCategory name={mappedData.categoryName} />
                  </TD>
                  <TD width={25}>{mapReportType(mappedData.templateName)}</TD>
                  <TD width={15}>
                    <Status type={mappedData.status} />
                  </TD>
                  <TD width={20}>
                    {format(new Date(mappedData.created), "EEEE, MMM d, yyyy")}
                  </TD>
                  <TD width={10}>
                    <Popup
                      trigger={
                        <ActionWrapper
                          onClick={() => {
                            handleDeleteGroup(item.id);
                          }}
                        >
                          {deleteIcon()}
                        </ActionWrapper>
                      }
                      content="Delete Group"
                      position="top center"
                    />
                    {mappedData?.markdownReport && (
                      <Popup
                        trigger={
                          <ActionWrapper
                            onClick={() => {
                              handleView({
                                id: item.id,
                                template_id: item.template_id,
                              });
                            }}
                          >
                            {viewIcon()}
                          </ActionWrapper>
                        }
                        content="View Report"
                        position="top center"
                      />
                    )}
                    {mappedData?.downloads &&
                      mappedData?.downloads?.length > 0 && (
                        <Popup
                          trigger={
                            <ActionWrapper
                              onClick={() => {
                                toggleRow(item.id);
                              }}
                            >
                              {isExpanded
                                ? caretUp("#2d70e2")
                                : caretDown("#2d70e2")}
                            </ActionWrapper>
                          }
                          content="Expand"
                          position="top center"
                        />
                      )}
                  </TD>
                </Row>
                {isExpanded &&
                  mappedData?.downloads &&
                  mappedData?.downloads?.map((file) => {
                    return (
                      <ExpandedRow key={file.share_id}>
                        <ExpandedContent>
                          <ExpandedFileTitle>
                            {file.primary} - {file.category}
                          </ExpandedFileTitle>
                          <ExpandedFileDownload>
                            {!file?.hasData ? (
                              <>No Data</>
                            ) : (
                              <>
                                <Popup
                                  trigger={
                                    <ActionWrapper
                                      onClick={() => {
                                        handleDownload({
                                          id: mappedData.id,
                                          share_id: file.share_id,
                                        });
                                      }}
                                    >
                                      {downloadIcon()}
                                    </ActionWrapper>
                                  }
                                  content="Download"
                                  position="top center"
                                />

                                <Popup
                                  trigger={
                                    <ActionWrapper
                                      onClick={() => {
                                        handleDeleteItem({
                                          id: mappedData.id,
                                          delete_share_id: file.share_id,
                                        });
                                      }}
                                    >
                                      {deleteIcon()}
                                    </ActionWrapper>
                                  }
                                  content="Delete File"
                                  position="top center"
                                />
                              </>
                            )}
                          </ExpandedFileDownload>
                        </ExpandedContent>
                      </ExpandedRow>
                    );
                  })}
              </React.Fragment>
            );
          })}
      {data?.length === 0 && (
        <Row>
          <TD width={100} centered>
            No reports found, create one to get started!
          </TD>
        </Row>
      )}

      {data?.length > itemsPerPage && (
        <Pagination
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={(e, { activePage }) => setActivePage(activePage)}
        />
      )}
    </Container>
  );
};

export default Table;

const MultipleCategory = ({ name }) => {
  return <Border>{name}</Border>;
};

const Status = ({ type }) => {
  const typeCleaned =
    type.toLowerCase() === "queued for processing" ? "Pending" : type;
  return (
    <StatusContainer color={statusColors?.[typeCleaned]?.container}>
      <StatusText color={statusColors?.[typeCleaned]?.text}>
        {typeCleaned === "New" ? "Pending" : typeCleaned}
      </StatusText>
    </StatusContainer>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 50px;
  font-family: Raleway;
`;

const StatusText = styled.div`
  color: ${(props) => props.color};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 125%;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
`;

const Border = styled.div``;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  font-family: "Raleway";
  margin-bottom: 0px;
  border-bottom: 1px solid #dfdfdf;
`;

const TH = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;

  color: #666d79;
`;

const Row = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.rowIsHovered ? "#F8FAFF" : "white")};
  border-radius: 5px;
`;

const TD = styled.div`
  width: ${(props) => props.width}%;
  font-size: 12px;
  color: #2a3039;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;
`;

const ExpandedRow = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f9f9f9;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: -10px;
`;

const ExpandedContent = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  font-size: 12px;
  color: #2a3039;
`;

const ExpandedFileTitle = styled.div`
  font-size: 12px;
  width: 82.5%;
  color: #2a3039;
  line-height: 1.5;
  justify-content: flex-start;
  align-items: center;
  display: flex;
`;

const ExpandedFileDownload = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  width: 17.5%;
  justify-content: flex-start;
`;
