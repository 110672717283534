import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Input } from 'semantic-ui-react'
import {
  process_feedback_comments,
  process_feedback_data,
  processCommentQuestions,
} from "../calculations";
import { useSelector, shallowEqual } from "react-redux";
import { filter_data } from "reports/Tag/calculations";
import { Pagination, Icon } from "semantic-ui-react";
import Spark from "assets/images/UI/mdi_sparkles-outline.svg";
import Empty from "./components/EmptyFeedback";
import FeedbackTag from "./FeedbackTag";
import { useLocation } from "react-router";
import ChatModal from "./components/ChatModal";
import { MiniILIcon } from "./components/Icons";

const COLOR_MAP = {
  "Very Negative": {
    background: "#FDF2F3",
    color: "#EF4F61",
  },
  "Slightly Negative": {
    background: "#FEF3F4",
    color: "#F27B88",
  },
  Neutral: {
    background: "#FFFAF1",
    color: "#FFA710",
  },
  "Slightly Positive": {
    background: "#F1FAF7",
    color: "#27CDA7",
  },
  "Very Positive": {
    background: "#F1FAF7",
    color: "#1B9A7E",
  },
};

const Feedback = ({ data, core_data, outcomeQ, navigation, originalData }) => {
  const [feedback, setFeedback] = useState();
  const location = useLocation();
  const [unfilteredFeedback, setUnfilteredFeedback] = useState();
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [AIChatOpen, setAIChatOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [displayedFeedback, setDisplayedFeedback] = useState();
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedUserFeedback, setSelectedUserFeedback] = useState(null);
  const pageSize = 10;
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [orientation, setOrientation] = useState(null);
  const [search, setSearch] = useState(null);
  const [selectedQuestion, setQuestion] = useState(null);
  const isOTP = location.pathname.includes("otp");
  const [feedbackType, setFeedbackType] = useState(isOTP ? 1 : 0);
  const [selectedOutcomes, setSelectedOutcomes] = useState([]);

  const { selectedFilters } = useSelector(
    (state) => ({
      selectedFilters: state.audit?.new_filters,
    }),
    shallowEqual
  );

  const getFactorName = (factor_id, dimension) => {
    return core_data?.questions?.dimensions?.[dimension]?.factors?.find(
      (f) => f.id == factor_id + 1
    )?.title;
  };

  const copilot = (
    <AIButton onClick={() => setAIChatOpen(true)}>
      Use the culture copilot <IMG2 src={Spark} />
    </AIButton>
  );

  function changedPage(e, { activePage }) {
    // console.log(activePage)
    setActivePage(activePage);
  }

  useEffect(() => {
    if (selectedConversation && unfilteredFeedback) {
      const userFeedback = unfilteredFeedback.filter(
        (item) => item.employee === selectedConversation.employee
      );

      setSelectedUserFeedback(userFeedback);
    }
  }, [selectedConversation]);

  useEffect(() => {
    if (feedback && feedback) {
      // When the page changes we need to update the displayed insights
      const start = (activePage - 1) * pageSize;
      const end = start + pageSize;
      let filteredFeedback = feedback;
      if (search) {
        filteredFeedback = feedback.filter((item) =>
          item.feedback?.toLowerCase().includes(search.toLowerCase())
        );
      }
      setTotalPages(Math.ceil(filteredFeedback.length / pageSize));
      setDisplayedFeedback(filteredFeedback.slice(start, end));
    }
  }, [activePage, feedback, search]);

  const getOutcomeName = (outcome_id, outcomeQ) => {
    return outcomeQ?.questions?.find((o) => Number(o.id) === Number(outcome_id))
      ?.name;
  };
  useEffect(() => {
    if (data?.[0]?.[0]) {
      if (
        !isOTP &&
        core_data?.questions?.comments?.length > 0 &&
        feedbackType === 1
      ) {
        setUnfilteredFeedback(
          processCommentQuestions(
            core_data?.questions?.comments,
            data?.[0],
            undefined
          )
        );
        return;
      }

      if (isOTP) {
        setUnfilteredFeedback(process_feedback_comments(data[0], undefined));
        return;
      } else {
        setUnfilteredFeedback(
          process_feedback_data(data?.[0], undefined, true)
        );
        return;
      }
    } else {
      if (
        (Array.isArray(data[0]) && data[0].length > 0) ||
        (!Array.isArray(data[0]) && data.length > 0)
      ) {
        if (
          !isOTP &&
          core_data?.questions?.comments?.length > 0 &&
          feedbackType === 1
        ) {
          setUnfilteredFeedback(
            processCommentQuestions(
              core_data?.questions?.comments,
              data,
              undefined
            )
          );
          return;
        }

        if (isOTP) {
          setUnfilteredFeedback(process_feedback_comments(data, undefined));
          return;
        } else {
          setUnfilteredFeedback(process_feedback_data(data, undefined, true));
        }
      }
    }
  }, [data, feedbackType]);

  useEffect(() => {
    let filteredData;
    let feedbackData;

    if (data?.[0]?.[0]) {
      filteredData = filter_data(data[0], selectedFilters);
    } else {
      filteredData = filter_data(data, selectedFilters);
    }

    if (
      !isOTP &&
      core_data?.questions?.comments?.length > 0 &&
      feedbackType === 1
    ) {
      feedbackData = processCommentQuestions(
        core_data?.questions?.comments,
        filteredData,
        navigation
      );

      if (selectedQuestion) {
        feedbackData = feedbackData.filter(
          (item) => item.id === selectedQuestion
        );
      }

      setFeedback(feedbackData?.filter((item) => item.feedback));
    } else if (isOTP) {
      feedbackData = process_feedback_comments(filteredData, navigation);

      if (selectedQuestion) {
        feedbackData = feedbackData.filter(
          (item) => item.id === selectedQuestion
        );
      }
      setFeedback(feedbackData?.filter((item) => item.feedback));
    } else {
      if (selectedFilter || selectedOutcomes.length > 0) {
        feedbackData = process_feedback_data(
          filteredData,
          navigation,
          false,
          selectedOutcomes
        );

        if (selectedFilter) {
          feedbackData = feedbackData.filter(
            (item) => item?.nlp?.sentiment === selectedFilter
          );
        }

        if (orientation) {
          feedbackData = feedbackData.filter(
            (item) => item?.nlp?.action_orientation === orientation
          );
        }
      } else if (orientation) {
        feedbackData = process_feedback_data(filteredData, navigation);

        feedbackData = feedbackData.filter(
          (item) => item?.nlp?.action_orientation === orientation
        );
      } else {
        feedbackData = process_feedback_data(filteredData, navigation);
      }

      setFeedback(feedbackData?.filter((item) => item.feedback));
    }

    // Reset page to 1 when filtering and recalculate total pages
    setActivePage(1);
    setTotalPages(Math.ceil(feedbackData.length / pageSize));
  }, [
    navigation,
    data,
    selectedFilters,
    selectedFilter,
    orientation,
    selectedQuestion,
    feedbackType,
    selectedOutcomes,
  ]);

  const getColor = (type) => {
    const colors = {
      "Very Negative": "#de2121",
      "Slightly Negative": "#de2121",
      Neutral: "#f7b531",
      "Slightly Positive": "#33b054",
      "Very Positive": "#33b054",
    };

    return colors[type];
  };

  if (core_data && core_data.noData) {
    return (
      <Container>
        <Empty />
      </Container>
    );
  }

  const displayEmptyPage = () => {
    // If the displayedFeedback length is 0 and there is no selected filters of any sort
    // return the empty page
    if (
      displayedFeedback?.length === 0 &&
      !selectedFilter &&
      !orientation &&
      selectedFilters?.length === 0 &&
      !search
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Container>
        <CultureLabel>Culture Analytics</CultureLabel>

        <TopTitle>Feedback Sentiment</TopTitle>
        <TopDescription>
          A summary of the sentiment of each comment from the survey. This
          feedback was labeled using a machine learning algorithm trained on
          culture feedback.
        </TopDescription>
        {!displayEmptyPage() && (
          <>
            <FeedbackTag
              selectedQuestion={selectedQuestion}
              setQuestion={setQuestion}
              outcomeQ={outcomeQ}
              data={feedback}
              setSelectedOutcomes={(selected) => {
                // If selected is an empty array, reset the selected outcomes
                if (selected.length === 0) {
                  setSelectedOutcomes([]);
                  return;
                }

                // If the selected outcome is already in the list, remove it
                if (
                  selectedOutcomes.find(
                    (outcome) => Number(outcome.id) === Number(selected.id)
                  )
                ) {
                  setSelectedOutcomes(
                    selectedOutcomes.filter(
                      (o) => Number(o.id) !== Number(selected.id)
                    )
                  );
                } else {
                  setSelectedOutcomes([...selectedOutcomes, selected]);
                }
              }}
              setFilter={(selected) => setSelectedFilter(selected)}
              setOrientation={(o) => {
                setOrientation(o?.value);
              }}
              selectedSentiment={selectedFilter}
              copilot={copilot}
              isOTP={isOTP}
              showComments={core_data?.questions?.comments?.length > 0}
              selectedFilter={selectedFilter}
              setFeedbackType={setFeedbackType}
              feedbackType={isOTP ? 1 : feedbackType}
            />
            <ChatModal
              open={open}
              setOpen={setOpen}
              data={selectedUserFeedback}
            />
          </>
        )}
        <FeedbackContainment>
          <FeedbackCentering>
            {displayedFeedback?.length > 0 && (
              <StyledInput
                icon={{ name: "search" }}
                placeholder="Search by keyword"
                value={search}
                onChange={(e) => {
                  setActivePage(1);

                  setSearch(e.target.value);
                }}
              />
            )}
            <>
              {(displayedFeedback &&
                ((displayedFeedback?.length > 0 &&
                  displayedFeedback.map((item, i) => {
                    if (item.feedback == "") return null;
                    return (
                      <Row
                        key={i}
                        onClick={() => {
                          setOpen(true);
                          setSelectedConversation(item);
                        }}
                      >
                        <BlueText>
                          {item?.outcome
                            ? getOutcomeName(item?.outcome, outcomeQ)
                            : getFactorName(item.factor, item.dimension)}{" "}
                          Feedback
                        </BlueText>

                        <Text>{item.feedback}</Text>
                        <Chips>
                          {item?.nlp?.sentiment && (
                            <Chip
                              style={{
                                backgroundColor:
                                  COLOR_MAP[item?.nlp?.sentiment]?.background,
                                color: COLOR_MAP[item?.nlp?.sentiment]?.color,
                              }}
                            >
                              {item?.nlp?.sentiment}
                            </Chip>
                          )}
                          {item?.nlp?.action_orientation && (
                            <Chip
                              style={{
                                backgroundColor:
                                  item.nlp.action_orientation ===
                                  "action_oriented"
                                    ? "#EBF1FD"
                                    : "#f0f0f0",
                                color:
                                  item.nlp.action_orientation ===
                                  "action_oriented"
                                    ? "#2C70E3"
                                    : "#333",
                              }}
                            >
                              {item.nlp.action_orientation === "action_oriented"
                                ? "Actionable"
                                : "Not Actionable"}
                            </Chip>
                          )}
                        </Chips>

                        {item.follow_up_question && (
                          <FollowUpBox>
                            <FollowUpTopSection>
                              {MiniILIcon()}
                              <FollowUpHeading>AI follow up</FollowUpHeading>
                            </FollowUpTopSection>
                            <FollowUpQ>{item.follow_up_question}</FollowUpQ>
                            <FollowUpR>{item.follow_up_response}</FollowUpR>
                          </FollowUpBox>
                        )}
                        <Caret name="chevron right" />
                      </Row>
                    );
                  })) || <Empty />)) || <>Loading...</>}
            </>
          </FeedbackCentering>
        </FeedbackContainment>
      </Container>
      {displayedFeedback?.length > 0 && (
        <PaginationWrapper>
          <Pagination
            activePage={activePage}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            totalPages={totalPages}
            onPageChange={changedPage}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

export default Feedback;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 85%;
  padding-right: 10px;
`;

const FollowUpR = styled.div`
  font-size: 10px;
  color: #666d79;
  margin-left: 20px;
  margin-top: 8px;
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  // Remove scroll if chat is open
  max-height: 100%;
  padding-bottom: 10px;
`;

const TopTitle = styled.h1`
  display: flex;
  align-items: flex-start;
`;
const TopDescription = styled.div``;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f0f0f0; // Only bottom border
  position: relative;
  padding: 20px;
  min-height: 100px;
  cursor: pointer; // Makes the row indicate it's clickable
`;

const CultureLabel = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;

  /* Primary/500 */
  color: #2d70e2;

  display: flex;
  align-items: flex-start;
  margin-bottom: -15px;
`;

const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const FollowUpTopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AIButton = styled.div`
  background: #2d70e2;
  font-family: "Raleway", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background: #333;
  }
  min-width: 200px;
  max-height: 45px;
  margin-left: 20px;
`;

const BottomTags = styled.div`
  display: flex;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 12px;
  color: #666d79;
`;

const IMG2 = styled.img`
  width: 20px;
  margin-left: 10px;
  margin-bottom: -5px;
`;

const TR = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
const Q = styled.div`
  font-weight: bold;
  margin-bottom: 20px;
  max-width: calc(100% - 200px);
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 1.6;
  color: #666d79;
  margin-bottom: 10px;
  padding-right: 20px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const Description = styled.div`
  margin-bottom: 30px;
  line-height: 1.4;
`;

const FeedbackContainment = styled.div`
  // Center the content 50% width
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-content: center;
  padding: 20px;
`;

const FeedbackCentering = styled.div`
  // Center the content 50% width
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const T2 = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Score = styled.div`
  position: absolute;
  right: 0;
`;

const StyledInput = styled(Input)`
  &&& {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #f6f9ff; // Light background color
    padding: 5px;

    input {
      border: none;
      background: none;
      font-size: 12px;
      color: #6b7280; // Soft grey color for text
      padding-left: 15px; // Space for placeholder text
      &::placeholder {
        color: #b0b7c3; // Light grey for placeholder text
      }
    }

    .icon {
      margin-right: 15px; // Space for the icon on the right
      color: #6b7280;
    }
  }
`;

const Caret = styled(Icon)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
`;

const Chip = styled.div`
  display: inline-block;
  padding: 2px 8px;
  border-radius: 8px;
  background-color: #f0f0f0;
  font-size: 12px;
  margin-right: 5px;
  font-weight: 600;
  color: #333;
`;

const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const FollowUpBox = styled.div`
  background-color: #ebf1fd;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  width: 90%;
`;

const FollowUpQ = styled.div`
  font-size: 10px;
  color: #333;
  margin-left: 20px;
  font-weight: bold;
`;

const FollowUpHeading = styled.div`
  font-weight: bold;
  color: #2d70e2;
  font-size: 12px;
  margin-left: 5px;
`;

const SentimentTag = styled.div`
  color: ${(props) => props.color};
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
`;

const Tag = styled.div`
  color: #524c71;
  font-weight: 600;
  font-size: 10px;
  background-color: #f8faff;
  padding: 4px 8px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 5px;
`;

const Tag2 = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

const Image = styled.img`
  width: 30px;
  margin-right: 40px;
`;

const Tags = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Action = styled.div`
  color: #2d70e2;
  font-size: 12px;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 20px;
`;

const TableTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-decoration: italic;
  margin-bottom: 5px;
`;

const BlueText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
`;