import { combineReducers } from "redux";

import errors from "./errors";
import answers from "./answers";
import auth from "./auth";
import google_auth from "./google_auth";
import account_default from "./account_default";
// data for reports
import emotion_definitions from "./emotion_definitions";
import moods from "./moods";
import personality from "./personality";
import culture from "./culture";
import leadership from "./leadership";
import employee_report from "./employee_report";
import employee_category from "./employee_category";
import industry_benchmark from "./industry_benchmark";
import engagement_data from "./engagement_data";
import chat_gpt from "./chat_gpt";
// signup
import signupSteps from "./signup_steps";
// forms
import departments from "./departments";
import employees from "./employees";
import employee_permission from "./employee_permission";
import ambassadors from "./ambassadors";
import employee_roles from "./employee_roles";
import employee_log from "./employee_log";
import employee_record from "./employee_record";
import employee_record_list from "./employee_record_list";
import employee_record_value from "./employee_record_value";
import onboarding from "./onboarding";
import organizations from "./organizations";
import teams from "./teams";
import team_access from "./team_access";
import team_types from "./team_types";
import payments from "./payments";
import subscription from "./subscription";
// debriefs
import debrief_response from "./debrief_response";
import debrief_schedule from "./debrief_schedule";
import debrief_token from "./debrief_token";
import debrief_email from "./debrief_email";
import survey_standard from "./surveystandards";
// production org contract
import prod_org_contract from "./prod_org_contract";
// settings
import fileupload from "./fileupload";
import surveystructure from "./surveystructure";
import price from "./price";
import signuptoken from "./signuptoken";
import surveytoken from "./surveytoken";
import surveyquestions from "./surveyquestions";
import surveyresponse from "./surveyresponse";
import surveyschedule from "./surveyschedule";
import surveyschedulefrequency from "./surveyschedulefrequency";
import surveycompliance from "./surveycompliance";
import leadersinschedule from "./leadersinschedule";
import leadersurveyparticipants from "./leadersurveyparticipants";
import teamsinschedule from "./teamsinschedule";
import personalityteams from "./personalityteams";
import personalityparticipants from "./personalityparticipants";
import validateEmail from "./validateEmail";
import emaildeliverystatus from "./emaildeliverystatus";
import surveysettinglist from "./surveysettinglist";
import pdfGenerator from "./pdf_generator";
import actionPlan from "./action_plans";
import recommendations from "./recommendations";

import checklist from "./checklists"

import debrief_pdf_report from "./debrief_pdf_report";
import PDFComments from "./pdf_comments";
import schedule_reports from "./schedule_reports";

// amabsaasdor switch
import selectedOrg from "./selectedOrg";

//Temp
import white_label from "./white_label";

//Audit
import audit from "./audit";

// Filters
import member_filters from "./member_filters";

// AI Chat
import ai_chat from "./ai_chat";
import ai_errors from "./ai_errors";
import ai_websocket from "./ai_websocket";

// Recs templates
import report_template from "./report_template";
import event_notification from "./event_notification";

// Data Stories
import data_stories from "./data_stories";

import user_manager from "./user_manager";
import task_templates from "./task_templates";
import task_responses from "./task_responses";

import story_logging from "./story_logging";
import download_url from "./download_url";

export default combineReducers({
  errors,
  auth,
  google_auth,
  account_default,
  selectedOrg,
  prod_org_contract,
  answers,
  ambassadors,
  culture,
  emotion_definitions,
  moods,
  leadership,
  personality,
  departments,
  employees,
  employee_permission,
  employee_report,
  employee_roles,
  employee_log,
  employee_record,
  employee_record_list,
  employee_record_value,
  employee_category,
  organizations,
  industry_benchmark,
  onboarding,
  debrief_response,
  debrief_schedule,
  debrief_token,
  debrief_email,
  debrief_pdf_report,
  engagement_data,
  chat_gpt,
  teams,
  team_access,
  fileupload,
  price,
  subscription,
  payments,
  signupSteps,
  surveyschedule,
  surveyquestions,
  surveystructure,
  surveyschedulefrequency,
  surveycompliance,
  surveysettinglist,
  survey_standard,
  personalityteams,
  personalityparticipants,
  leadersinschedule,
  leadersurveyparticipants,
  teamsinschedule,
  signuptoken,
  surveytoken,
  surveyresponse,
  team_types,
  validateEmail,
  emaildeliverystatus,
  white_label,
  audit,
  pdfGenerator,
  actionPlan,
  checklist,
  PDFComments,
  member_filters,
  ai_chat,
  ai_errors,
  ai_websocket,
  recommendations,
  report_template,
  event_notification,
  data_stories,
  schedule_reports,
  user_manager,
  task_templates,
  task_responses,
  story_logging,
  download_url,
});
