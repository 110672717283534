import Logo from "./assets/images/logo_blue_main.png";

// import OtpLogo from "assets/images/otp/Logo.png";
export const SL =
  "https://firebasestorage.googleapis.com/v0/b/charactercode-a247a.appspot.com/o/email%2FPicture1.png?alt=media&token=223020fd-96d0-4549-aec8-615ef47e5562";

// google auth api
export const GOOGLE_CLIENT_ID='444691808577-2ok712r0f1umjrgl5ndo0s3rt08p7lii.apps.googleusercontent.com'
export const GOOGLE_SCOPE = "https://www.googleapis.com/auth/userinfo.email"

export const Domain = {
  id: 1,
  name: "Demo Innerlogic",
  url: "demo.innerlogic.com",
  image: Logo,
};

// Publishable key
export const StripeKey = "pk_test_bowWtykmtAwZxvD0bvdXEycG00LczLxeDB";

export const SentryDSN_DEV = "";
export const SentryDSN_PROD =
  "https://53de57189a184c5db40d1d7ea9394a96@o1167548.ingest.sentry.io/6258716";
export const SentryDSN_STG =
  "https://40343aed15d841c6b882b632552007ee@o1167548.ingest.sentry.io/6258777";

export const serverUrl = "https://stg-survey.innerlogic.com";
export const returnUrl = "https://stg-app.innerlogic.com/account/login";

export const Environment = "Development";
// go auth login
export const goAuthApiServer = "https://auth.innerlogic.com";
export const goAuthApiStaging = "https://stg-auth-api.innerlogic.ca";
// django login
export const serverApi = "https://api.innerlogic.com"
// export const staginApi = 'https://stg-api.innerlogic.ca'
export const staginApi = 'https://django-api-staging.innerlogic.ca'

//report api
export const reportProductionApi = "https://reports-api.innerlogic.ca"
export const reportStagingApi = "https://stg-reports-api.innerlogic.ca"

// ml api
export const mlProductionApi = "https://ml.innerlogic.ca"
export const mlStagingApi = "https://ml-stg.innerlogic.ca"
// chat api
export const chatProductionApi = "https://ml-websocket.innerlogic.ca"
export const chatStagingApi = "https://ml-stg-websocket.innerlogic.ca"
// websocket chat
export const wsProductionApi = "wss://ml-websocket.innerlogic.ca"
export const wsStagingApi = "wss://ml-stg-websocket.innerlogic.ca"

// // to use production uncomment these lines
// export const currentApi = serverApi;
// export const mlApi = mlProductionApi
// export const chatApi = chatProductionApi;
// export const curGoApi = goAuthApiServer;
// export const wsApi = wsProductionApi;

// // to use staging uncomment these lines
export const currentApi = staginApi;
export const mlApi = mlStagingApi
export const reportApi = reportStagingApi
export const curGoApi = goAuthApiStaging;
export const chatApi = chatStagingApi;
export const wsApi = wsStagingApi;

  export const config = {
    // switch to go for login
    loginapi: `${curGoApi}`,
    // use django api for login changed Oct 3 2022
    // loginapi: `${currentApi}/api/account`,
    signupapi: `${currentApi}/api/signup`,
    // surveyapi: `${currentApi}/api/survey`,
    appapi: `${currentApi}/api/organization`,
    // ML server
    mlapi: `${mlApi}`,
    reportApi,
    // payment: `${currentApi}/api/payment`,
    website: `${currentApi}/api/web`,
    chatApi, 
    wsApi,  
  };

export default config;

