import styled,{keyframes} from "styled-components";
import React, { useEffect, useState } from "react";


const FactorRow = ({ data, index,selected,summary,min,max}) => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [questionData, setQuestionData] = useState([]);


    const getFactorScore = (item) => {
        return (Object.values(item.culture_scores.factor_scores).find(i=>i.factor == summary.name)?.scores*10).toFixed(0)
    };

    const getColorGrade = (score) => {
        if(!score){
            return '#fff'
        }
         // Normalize the score to a value between 0 and 1
         let normalizedScore = (score - min) / (max - min);

         if(score > max){
             normalizedScore = 1
         }
     
         if(score < min){
             normalizedScore = 0
         }

    // Define the RGB values for the colors
    const redStart = { r: 241, g: 80, b: 98 };  // #F15062
    const greenEnd = { r: 39, g: 205, b: 167 }; // #27CDA7

    let red, green, blue;

    if (normalizedScore <= 0.5) {
        // Interpolate between redStart and white (255, 255, 255)
        const ratio = normalizedScore * 2; // Scale between 0 and 1
        red = redStart.r + Math.floor((255 - redStart.r) * ratio);
        green = redStart.g + Math.floor((255 - redStart.g) * ratio);
        blue = redStart.b + Math.floor((255 - redStart.b) * ratio);
    } else {
        // Interpolate between white (255, 255, 255) and greenEnd
        const ratio = (normalizedScore - 0.5) * 2; // Scale between 0 and 1
        red = 255 + Math.floor((greenEnd.r - 255) * ratio);
        green = 255 + Math.floor((greenEnd.g - 255) * ratio);
        blue = 255 + Math.floor((greenEnd.b - 255) * ratio);
    }

    // Return the color in RGB format
    return `rgb(${red}, ${green}, ${blue})`;
      }

      useEffect(()=>{
            if(data){
                let questions = Object.values(data[0].culture_scores.item_scores).filter(i=>i.factor_name == summary.name)
                setQuestionData(questions)
            }
      },[data])


      const get_item_scores = (question) => {
        let total_responses = 0 
        let total_scores = data.map((item)=>{
            let score = Object.values(item.culture_scores.item_scores).find(i=>i.question == question).scores
            let responses = item.responses

            if(!score){
                return 0
            }
            total_responses += responses
            return score*responses
        }).reduce((a,b)=>a+b,0)


        return (total_scores/total_responses*10).toFixed(0)
      }

    return (

        <>
        <TableRow2 selected={selected==index} onClick={()=>setSelectedRow(!selectedRow)}>
            {
            selected == index && (
                <>
                <FirstColumnCell2>{summary.name}</FirstColumnCell2>
                <TableCell color={getColorGrade((summary.scores*10).toFixed(0))}>{(summary.scores*10).toFixed(0)}</TableCell>
                {
                    data.map((item) => (
                        <TableCell color={getColorGrade(getFactorScore(item))}>{getFactorScore(item)}</TableCell>
                    ))
                }
                </>
            )
            }
            
        </TableRow2>

        {selectedRow && selected==index && (
                <>
                {questionData.map((question, idx) => {
                   return (
                    <TableRow2 selected={selectedRow}>
                        <FirstColumnCell3>{question.question}</FirstColumnCell3>
                        <TableCell color={getColorGrade(get_item_scores(question.question))}>{get_item_scores(question.question)}</TableCell>
                        
                        {
                            data.map((item) => {
                                let score = (Object.values(item.culture_scores.item_scores).find(i=>i.question == question.question).scores*10).toFixed(0)
                                return <TableCell color={getColorGrade(score)}>{score!='NaN' ? score : ""}</TableCell>
                            })
                        }
                    </TableRow2>
                )}  
                )}


                </>

        )}

        </>
    )

}

export default FactorRow;


const TableRow2 = styled.tr`
    max-height:${props=>props.selected ? '200px' : '0px'};
    height: ${props=>props.selected ? '35px' : '0px'};
    overflow:hidden;
    transition:all 0.8s;
`;



const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 0;
  text-align: center;
  width: 50px;
  height:100%;

    background-color: ${props=>props.color ? props.color : '#fff'};

`;


const FirstColumnCell2 = styled.td`
  border: 1px solid #ddd;
  padding: 0 16px;
  text-align: left;
  width: 350px; /* Wider first column */
 height:100%;
  background-color: #fff;
  color:#2A3039;
  font-size:14px;
  font-weight:600;
  cursor:pointer;



`;

const FirstColumnCell3 = styled.td`
  border: 1px solid #ddd;
  padding: 5px 24px;
  text-align: left;
  width: 350px; /* Wider first column */
 height:100%;
  background-color: #fff;
  color:#2A3039;
  font-size:12px;
  cursor:pointer;

`;


const QuestionData = [
    {
        question: 'We help and support one another through emotional hardships (e.g. tough times).We help and support one another through emotional hardships (e.g. tough times).',
        overall: 76,
        customerService: 4,
        sales: -3,
        marketing: -6,
        operations: -16,
    },
    {
        question: 'How do you feel about the customer service?',
        overall: 77,
        customerService: 5,
        sales: -2,
        marketing: -5,
        operations: -15,
    },
    {
        question: 'How do you feel about the sales team?',
        overall: 78,
        customerService: 6,
        sales: -1,
        marketing: -4,
        operations: -14,
    }
]