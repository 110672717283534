


export const get_average_anchor = (data, filters, anchor, core_data) => {
  // Helper function to calculate the average of an array of numbers
  const average_array = (arr) => {
    if (!arr.length) return 0;
    const sum = arr.reduce((acc, val) => acc + val, 0);
    return sum / arr.length;
  };

  // Find the selected category based on the anchor ID
  const selectedCategory = core_data?.categories[0]?.categories.find(
    (f) => f.id === anchor
  );

  // If the selected category is not found, return empty values
  if (!selectedCategory) return { series: [], categories: [], yMin: 0, yMax: 0 };

  // Initialize an array to hold the options with their calculated averages
  const optionsWithAverages = selectedCategory.options.map((op) => {
    // Filter the data based on the current option's ID
    const filtered_data = data[0].filter(
      (f) =>
        f.categories.find((c) => c.id === anchor)?.response === op.id
    );

    // Extract all responses from the questions within the filtered data
    const overall_responses = filtered_data
      .map((f) => f.questions.map((q) => q.response))
      .flat();

    // Calculate the average response and scale it by multiplying by 10
    const average = average_array(overall_responses) * 10;

    return {
      name: op.name,
      average: average,
    };
  });

  // Sort the options from highest to lowest average
  const sortedOptions = optionsWithAverages.sort((a, b) => b.average - a.average);

  // Extract categories and series data
  const categories = sortedOptions.map((option) => option.name);
  const seriesData = sortedOptions.map((option) => option.average);

  // Determine yMin and yMax with padding
  const yMin = Math.min(...seriesData)-5< 0? 0 : Math.min(...seriesData) - 5;
  const yMax = Math.max(...seriesData) + 5 > 100? 100 : Math.max(...seriesData) + 5;

  // Structure the data for ApexCharts
  const series = [
    {
      name: 'Score',
      data: seriesData,
    },
  ];

  return {
    series,
    categories,
    yMin,
    yMax,
  };
};


const average_array = (arr) => {
    return arr.reduce((a, b) => a + b, 0) / arr.length;
  };
  