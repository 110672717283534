
// This file contains the calculations for the Tag report


// This function returns the most recent responses for each primary factor
export const get_most_recent = (responses) => {
    let tracker = {};
    return {
      date: null,
      responses: responses
        .filter((i) => {
          if (!(i.name in tracker)) {
            tracker[i.name] = i.date;
          }
  
          return i.date === tracker[i.name];
        })
        .map((i) => i.responses)
        .flat()
        .map((i) => i.response)
        .filter((f) => !Array.isArray(f)),
    };
  };

  export const get_last_responses = (responses) => {
    let tracker = {};
  
    return responses
      .filter((i) => {
        if (!(i.name in tracker)) {
          tracker[i.name] = [i.date];
        } else if (tracker[i.name].length === 1) {
          tracker[i.name].push(i.date);
        }
  
        if (tracker[i.name].length === 2) {
          return i.date == tracker[i.name][1];
        }
        return false;
      })
      .map((i) => i.responses)
      .flat()
      .map((i) => i.response)
      .filter((f) => !Array.isArray(f));
  };



export function filter_data(data, filters) {
  function responseMatchesFilter(response) {
    // Extract all the response ids and subfactor ids
    const responseIds = response?.categories?.map((cat) => cat.response);
    const subfactorIds = response?.categories
      ?.filter((cat) => cat?.subfactor)
      ?.map((cat) => cat.subfactor);
    // Group filters by parent (NON SUBFACTOR)
    const groupedFilters = filters
      .filter((cat) => !cat.isSubfactor)
      .reduce((acc, filter) => {
        if (!acc[filter.parent]) acc[filter.parent] = [];
        acc[filter.parent].push(filter);
        return acc;
      }, {});

    // Group subfactor filters into a new array
    const subfactorFilters = filters.filter((cat) => cat.isSubfactor);

    let isValid = false;
    let usesSubfactor = false;
    // Check for every parent
    for (let parent in groupedFilters) {
      const requiredFilters = groupedFilters[parent];
      // Check if at least one filter matches
      const hasMatchingFilter = requiredFilters.some((filter) => {
        return responseIds.includes(filter.id);
      });

      // If none of the required ids for a given parent are present in the response, filter it out
      if (!hasMatchingFilter && !isValid) {
        return false;
      }
    }

    // Check for every subfactor
    for (let subfactor of subfactorFilters) {
      usesSubfactor = true;
      const hasMatchingFilter = subfactorIds.includes(subfactor.id);
      if (hasMatchingFilter) {
        isValid = true;
        break;
      }
    }

    if (usesSubfactor) {
      return isValid;
    }

    return true;
  }

  return data.filter(responseMatchesFilter);
}

export const get_overall_average = (data) => {
  let totalSum = 0;
  let totalCount = 0;

  data.forEach((item) => {
    item.questions.forEach((question) => {
      if (typeof question.response === "number") {
        totalSum += question.response;
        totalCount++;
      }
    });
  });

  return totalCount ? totalSum / totalCount : 0; // Avoid division by zero
};

const sum_array = (arr) => {
    if(!arr){
        return 0
    }

    let sum = 0;
    arr.forEach(item => {
        sum += item;
    });
    return sum;
}

export const get_hr_flags = (data) => {
    let flags = 0;
    let feedback_quality = 0;
    let totalCount = 0;

    data.forEach(item => {
        item?.ml_feedback?.feedback?.forEach(question => {

                flags+= sum_array(question?.conversation_analysis?.hr_flag)

                question?.conversation_analysis?.quality.forEach(quality => {
                    feedback_quality+=quality
                    totalCount++
                })
        });
    });

    return {flags,feedback_quality:feedback_quality/totalCount}
}

const get_factor_average = (data, factor,dimension) => {
    
        let totalSum = 0;
        let totalCount = 0;
    
        data.forEach(item => {
            item.questions.forEach(question => {
                if (question.factor === factor && question.id === dimension) {
                    totalSum += question.response;
                    totalCount++;
                }
            });
        });
    
        return totalCount ? totalSum / totalCount : 0;  // Avoid division by zero
    
}

const get_item_scores = (data, index,dimension,factor) => {
    let scores = []
    factor.questions.forEach((item,question)=>{
        let totalSum = 0;
        let totalCount = 0;
        data.forEach(response=>{
            response.questions.forEach((q,i)=>{
                if(q.factor === index && q.id === dimension && q.q === question){
                    totalSum += q.response;
                    totalCount++;
                }
            })
        })
        scores.push({question:item.q,score:totalCount ? totalSum / totalCount : 0})
    })
    return scores
}

const get_pillar_standard = (standards,index,dimension) => {
   return standards?.response.pillars.find(f=>f.id==dimension && f.factor==index)
}

export const get_scores_per_pillar = (data,survey,org_data,last_data,standards) =>{
    let scores = []
    survey.questions.dimensions.map((item,dimension)=>{
        item.factors.map((factor,index)=>{
           scores.push({name:factor.title,
                        score:get_factor_average(data,index,dimension),
                        item_scores:get_item_scores(data,index,dimension,factor),
                        dimension,
                        factor:index,
                        last_score:get_factor_average(last_data,index,dimension),
                        pillar_standard:get_pillar_standard(standards,index,dimension),
                        org_score:get_factor_average(org_data,index,dimension)})

        })
    })
    return scores
}

export const getStructuredFeedback = (data) => {
    let feedback = []
    data?.map((item)=>{
        item.feedback.map((f)=>{
           feedback.push(f)
        })
    })

    return feedback
}