import styled, { keyframes } from 'styled-components';
import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import FactorContent from "./FactorContent";
import OutcomeContent from "./OutcomeContent";
import {aggregateResponsesByQuarter} from './calculations'

const FactorAnalytics = ({
    core_data,
    outcomeQ,
    data,
    outcomeStandards,
    navigation,
    rule,
    lastData
  }) =>{
    const [selected,setSelected] = useState(null)
    const [historical_data, setHistoricalData] = useState(null)
    const [selectedType, setSelectedType] = useState('factor')
    const [correctedData, setCorrectedData] = useState(null)

    const get_culture_audit_reports = useSelector(
        (state) => state.debrief_schedule?.culture_audit_reports,
        shallowEqual
      );

    const survey_version = useSelector(
        (state) => state.audit.survey_version,
        shallowEqual
        );

    useEffect(()=>{
        setHistoricalData(aggregateResponsesByQuarter(get_culture_audit_reports.response,survey_version))
    },[get_culture_audit_reports,survey_version])

    useEffect(()=>{
        if(core_data){
            let item = core_data.questions.dimensions[0].factors[0]
            item['dimension'] = 0
            setSelected(item)
        }
    },[core_data])

    useEffect(()=>{
        if(data[0][0]){
            return setCorrectedData(data)
        }
        
        setCorrectedData([data])
    },[data])

    const selectType = (item,type,dimension) =>{
        let _item = item
        if(type == 'factor'){
            _item['dimension'] = dimension
        }
        setSelected(_item)
        setSelectedType(type)
    }


    if(!core_data){
        return <div>Loading...</div>
    }



    return (
        <Container>
        <BlueTitle>Culture Analytics</BlueTitle>
        <Title>Factor Analysis</Title>
        

        <Content>
            <LeftBar>
                <BarTitle>Culture Factors</BarTitle>
                    {core_data.questions.dimensions.map((item,i)=>{
                        return item.factors.map((factor)=>{
                            return <BarLink 
                                    onClick={()=>selectType(factor,'factor',i)}
                                    selected={selected && selected.title === factor.title}
                                    >{factor.title}</BarLink>
                        })
                    })}
                <div style={{marginTop:20}}/>
                <BarTitle>Outcomes</BarTitle>
                    {outcomeQ?.questions.map((item)=>{
                        return <BarLink 
                                onClick={()=>selectType(item,'outcome')}
                                selected={selected && selected.name === item.name}
                                >{item.name}</BarLink>
                    })
                    }
            </LeftBar>

            <RightBar>
                    {selectedType == 'factor'  && selected &&
                        <FactorContent 
                        factor={selected} 
                        core_data={core_data} 
                        outcomeQ={outcomeQ} 
                        data={correctedData} 
                        selectedFactor={selected}
                        outcomeStandards={outcomeStandards} 
                        navigation={navigation}
                        historical_data={historical_data}
                        lastData={lastData}
                        rule={rule}/>}

                    {selectedType == 'outcome'  && selected && <OutcomeContent
                        factor={selected} 
                        core_data={core_data} 
                        outcomeQ={outcomeQ} 
                        data={correctedData} 
                        selectedFactor={selected}
                        outcomeStandards={outcomeStandards} 
                        navigation={navigation}
                        historical_data={historical_data}
                        lastData={lastData}
                        rule={rule}/>
                    }
            </RightBar>

        </Content>
    </Container>
    )
}

export default FactorAnalytics;



const Container = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    font-family: 'Raleway';
`

const BlueTitle = styled.div`
    color:#2D70E2;
    font-size:14px;
    font-weight:600;
    margin-bottom:15px;
    margin-top:-20px;
`

const Title = styled.div`
    font-size:28px;
    font-weight:bold;
    margin-bottom:30px;
    color:#2A3039;
    display:flex;
`
const Description = styled.div`
    font-size:14px;
    font-weight:400;
    margin-bottom:30px;
    line-height:1.5;
    color:#2A3039;
    max-width:800px;
`

const Content = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    height:100%;
    margin-top:20px;
`

const LeftBar = styled.div`
    width:200px;
    height:100%;
    border-right:1px solid #ddd;
    padding:20px;
    padding-left:0px;
    padding-top:0px;
    margin-top:20px;
`

const RightBar = styled.div`
    width:calc(100% - 200px);
    height:100%;
    padding:20px;
`

const BarTitle = styled.div`
    color:#2A3039;
    font-weight:600;
    font-size:18px;
    margin-bottom:10px;
`

const BarLink = styled.div`
    color:${props=>props.selected ? '#2D70E2' : '#666D79'};
    font-weight:${props=>props.selected ? 'bold' : '400'};
    padding:5px;
    cursor:pointer;
    font-size:12px;

    &:hover{
        background-color:#F5F5F5;
    }
`

