import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import IconImg from "assets/images/UI/person_green.svg"
import PredictionSlider from "./components/PredictionSlider";
import {Modal} from "semantic-ui-react"
import {Link} from "react-router-dom"


function calculateAdjustedCoefficient(actualScore, minScore, maxScore, globalBenchmark, regressionCoefficient) {
    // Normalize the score
    const normalizedScore = (actualScore - minScore) / (maxScore - minScore);

    const potentialForOptimization = 100 - (actualScore - globalBenchmark);
    const adjustedCoefficient = regressionCoefficient * potentialForOptimization;

    return adjustedCoefficient;
}



const ROITable = ({data,core_data,title})=>{

    const [tableData,setTableData] = useState([])
    useEffect(()=>{
        if(data){
            let _data = []
            Object.keys(data?.score?.model).map((item)=>{
                let _item = data?.score.model[item]
                let benchmark = core_data?.standards?.response?.pillars?.find(
                  (f) =>
                    f.factor == _item.index.factor &&
                    f.id == _item.index.dimension
                )?.average;

                benchmark = benchmark ? benchmark : 7
                let adjustedCoefficient = calculateAdjustedCoefficient(
                        _item.average_score*10,
                        0,
                        100,
                        benchmark*10,
                        _item.coefficient
                    );

                if(_item.importance > 0){
                    _data.push({
                        name:item,
                        value:adjustedCoefficient,
                        score:_item.average_score,
                        benchmark:benchmark,
                        coefficient:_item.coefficient,
                        impact:_item.coefficient * 0.1 +_item.SE*1.96 * 0.1 * _item.coefficient,
                        lower_impact:_item.coefficient * 0.1 -_item.SE*1.96 * 0.1 * _item.coefficient
                    })
                }
                    
            })

            _data.sort((a,b)=>b.value-a.value)
            setTableData(_data)
        }
    },[data,core_data])


    if(!data?.score){
        return ""
    }


   
   

    return (
        <Container>
            {/* <Title>ROI Analysis</Title> */}
            <Content>
                <IMG src={IconImg} alt="icon"/>
            <Description>
                {tableData[0]?.name} has the highest ROI for improving {title?.toLowerCase()} in your organization.
            </Description>

            <Text>
            {tableData[0]?.name} scored <strong>{(tableData[0]?.score*10).toFixed(0)}%</strong> compared to the benchmark of <strong>{(tableData[0]?.benchmark*10).toFixed(0)}%</strong>,
            which, relative to the other factor scores and predicted impact, has the highest potential for improving {title}.
            </Text>

            <T1>Predicted Impact</T1>
            <Large>
                +{(tableData[0]?.lower_impact*100).toFixed(1)} to {(tableData[0]?.impact*100).toFixed(1)}% {title?.toLowerCase()} gain 
            </Large>
            <Small>
                * Based on the 95% confidence interval by improving {tableData[0]?.name.toLowerCase()} by 10%.
            </Small>

            <div style={{display:'flex',marginTop:20}}>
                
               

                {/* <Button>
                <Link to="/leader/prototype/insightsV2">Get ROI report</Link>

                </Button> */}

                <Modal 
                    trigger={<Button>Predictive Model</Button>}
                    size="large">
                    <Modal.Content>
                    <PredictionSlider factors={Object.values(data?.score.model)} outcomeName={title?.toLowerCase()}/>
                    </Modal.Content>
                </Modal>
            </div>

           

            </Content>

          

        </Container>
    )
}


export default ROITable;




const Container = styled.div`
    padding-bottom:50px;
    width:calc(50% - 20px);
    background-color:#F2FDFA;
    padding:20px;
    border-radius:10px;
`

const Linked = styled.div`
    color:#1D9A7D;
    cursor:pointer;
    font-weight:600;
    background-color:#D4F5ED;
    width:calc(50% - 10px);
    padding:15px 20px;
    border-radius:10px;
    display:flex;
    justify-content:center;
`

const Button = styled.div`
    background-color:#1D9A7D;
    color:#EAFBF7;
    padding:15px 20px;
    display:flex;
    justify-content:center;
    font-weight:600;
    border-radius:10px;
    width:calc(50% - 10px);
    margin-right:20px;
    cursor:pointer;

    a{
        color:#EAFBF7;
    }

`

const IMG = styled.img`
    width:40px;
    margin-bottom:20px;
`

const Large = styled.div`
    font-size:20px;
    font-weight:bold;
    margin-top:5px;
    margin-bottom:10px;
    color:#2A3039;
`

const Title = styled.div`
color: #2d70e2;
text-transform: uppercase;
font-size: 14px;
font-weight: 600;
margin-bottom: 20px;
display: flex;
`

const T1 = styled.div`
    font-weight:bold;
    margin-top:30px;
    font-size:14px;
     color:#1D9A7D;
     text-transform:uppercase;
`

const Description = styled.div`
    font-size:20px;
    line-height: 1.4;
    font-weight:700;
    color:#2A3039;
    `;

const Highlight = styled.span`
    color:#1D9A7D;
    font-weight:bold;
    `;

const Content = styled.div`
   
`

const Text = styled.div`
    margin-top:20px;
    line-height:1.6;
`

const Small = styled.div`
    font-size:10px;
    margin-top:15px;
    color:#666D79;
    font-weight:600;
`

