import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import DataTypeDropdown from "./FeedbackTypeDropdown";
import { useSelector, shallowEqual } from "react-redux";
import ClickAwayListener from "react-click-away-listener";
import { Icon } from "semantic-ui-react";
import Filters from "./Filters";
import QuestionDropdown from "./QuestionDropdown";
import RadioButtonGroup from "./components/RadioButton";
import HorizontalBarChart from "components/Charts/HorizontalBarChart";
import { convertFeedbackData } from "components/Charts/barChartUtils";

const chartOptions = [
  { name: "Very Negative", color: "#de2121" },
  { name: "Slightly Negative", color: "#F57373" },
  { name: "Neutral", color: "#F7B531" },
  { name: "Slightly Positive", color: "#80C7A0" },
  { name: "Very Positive", color: "#3D8B67" },
];

const getContextQuestions = (data) => {
  const uniqueQuestions = [];

  data?.forEach((item) => {
    const existingQuestion = uniqueQuestions.find((q) => q.id === item.id);
    if (!existingQuestion) {
      uniqueQuestions.push({
        id: item.id,
        value: item.id,
        name: item.question,
      });
    }
  });

  return uniqueQuestions;
};

const FeedbackTag = ({
  data,
  setFilter,
  setOrientation,
  outcomeQ,
  selectedSentiment,
  copilot,
  selectedFilter,
  isOTP,
  selectedQuestion,
  setQuestion,
  showComments,
  setFeedbackType,
  feedbackType,
  setSelectedOutcomes,
}) => {
  const [series, setSeries] = useState(option.series);
  const [options, setOptions] = useState(option.options);
  const [contextQuestions, setContextQuestions] = useState();
  const [chartData, setChartData] = useState([]);
  const [immutableChartData, setImmutableChartData] = useState();

  const [frequencyData, setFrequencyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [originalSeries, setOriginalSeries] = useState();
  const { core_data } = useSelector(
    (state) => ({
      core_data: state.audit?.core_data,
    }),
    shallowEqual
  );

  useEffect(() => {
    setLoading(true);
    const sentimentData = {};

    data?.forEach((item) => {
      const sentiment = item?.nlp?.sentiment;
      const action_oriented =
        item?.nlp?.action_orientation === "action_oriented";

      if (sentiment) {
        if (!sentimentData[sentiment]) {
          sentimentData[sentiment] = { total: 0, action_oriented: 0 };
        }

        sentimentData[sentiment].total += 1;

        if (action_oriented) {
          sentimentData[sentiment].action_oriented += 1;
        }
      }
    });

    const sentiment_order = [
      "Very Negative",
      "Slightly Negative",
      "Neutral",
      "Slightly Positive",
      "Very Positive",
    ];

    // Create series data
    const _series = sentiment_order.map((sentiment) => {
      const total = sentimentData[sentiment]?.total || 0;
      return { name: sentiment, data: [total] };
    });

    // Calculate actionable percentages per sentiment
    const actionablePercentages = sentiment_order.map((sentiment) => {
      const total = sentimentData[sentiment]?.total || 0;
      const actionOriented = sentimentData[sentiment]?.action_oriented || 0;
      const percentage = total > 0 ? (actionOriented / total) * 100 : 0;
      return percentage.toFixed(1);
    });

    // Convert data using updated convertFeedbackData function
    const chartData = convertFeedbackData(
      _series,
      chartOptions,
      actionablePercentages
    );

    setChartData(chartData);
    if (!immutableChartData && chartData.length > 0 && chartData?.[0]) {
      let naNCount = 0;
      chartData.forEach((item) => {
        if (item?.percent === "NaN") {
          naNCount += 1;
        }
      });
      if (naNCount < chartData.length) {
        setImmutableChartData(chartData);
      }
    }
    setLoading(false);
  }, [data]);

  const getFeedbackPercentage = () => {
    let total = 0;
    let action = 0;
    data?.forEach((item) => {
      const action_oriented = item?.nlp?.action_orientation;
      if (action_oriented === "action_oriented") {
        action += 1;
      }
      total += 1;
    });

    if (total === 0) {
      return 0;
    }

    return (action / total) * 100;
  };

  useEffect(() => {
    if (!contextQuestions) {
      setContextQuestions(getContextQuestions(data));
    }
  }, [data]);

  const handleOrientationChange = (newOrientation) => {
    setOrientation(newOrientation);
  };

  return (
    <>
      <Container>
        {showComments && (
          <RadioButtonGroup
            onChange={(e) => {
              setFeedbackType(Number(e));
            }}
            options={[
              { label: "Feedback", value: 0, name: "Feedback" },
              { label: "Comments", value: 1, name: "Comments" },
            ]}
            selected={feedbackType}
          />
        )}

        <BottomRow>
          <Filters
            outcomes={outcomeQ?.questions}
            onSelectOutcomes={setSelectedOutcomes}
            onOrientationChange={handleOrientationChange}
            onSentimentFilter={(sentiments) => {
              setFilter(sentiments);
              setSelectedIndex(null);
            }}
          />
        </BottomRow>
        {!loading && immutableChartData?.length > 0 && (
          <ChartContainer>
            <HorizontalBarChart
              data={immutableChartData}
              selectedIndex={selectedIndex}
              tagline={
                <Action>
                  <strong> {getFeedbackPercentage().toFixed(0)}%</strong> of
                  this feedback was tagged as actionable.
                </Action>
              }
              onSelect={(name, index) => {
                setFilter(name);
                setSelectedIndex(index);
              }}
            />
          </ChartContainer>
        )}
        {loading && <LoadingBox />}

        {(isOTP || feedbackType === 1) && (
          <DropdownSection>
            <QuestionDropdown
              label="Filter by question"
              selectedOption={selectedQuestion}
              setSelected={(o) => setQuestion(o)}
              options={contextQuestions(data)}
            />
          </DropdownSection>
        )}
      </Container>
    </>
  );
};

export default FeedbackTag;

const Dropdown = ({ options, setSelected, label, selectedOption }) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [selected, setSelectedOption] = useState(null);

  const selectOption = (option) => {
    setOpen(false);
    setSelected(option?.value);
    setSelectedOption(option);
  };

  const clearSelection = () => {
    setSelected(null);
    setSelectedOption(null);
    setTimeout(() => {
      setOpen(false);
    }, 1);
  };

  useEffect(() => {
    if (selectedOption) {
      setSelectedOption(
        options.find((option) => option.value === selectedOption)
      );
    } else {
      setSelectedOption(null);
    }
  }, [selectedOption]);

  return (
    <Box>
      <Label>{label}</Label>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <DropdownContainer
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Nav onClick={() => setOpen(!open)}>
            <Selection>{selected?.name || "Select an option"}</Selection>
            <Icon
              name="triangle down"
              style={{ marginTop: -4, color: "#B3B3B3" }}
            />
            {selected && (
              <ClearButton onClick={clearSelection}>
                <Icon name="times" style={{ color: "#2D70E2" }} />
              </ClearButton>
            )}
          </Nav>
          {open && (
            <DropdownContent>
              {options.map((option, i) => (
                <Option key={i} onClick={() => selectOption(option)}>
                  {option.name}
                </Option>
              ))}
            </DropdownContent>
          )}
        </DropdownContainer>
      </ClickAwayListener>
    </Box>
  );
};

const LoadingBox = styled.div`
  height: 150px;
  width: 800px;
  margin-bottom: 15px;
  background-color: #f0f0f0; /* Grey background */
  animation: shimmer 1.5s infinite linear; /* Shimmer animation */
  opacity: 0.2;

  @keyframes shimmer {
    0% {
      background-color: #555; /* Darker grey */
    }
    50% {
      background-color: #999; /* Lighter grey */
    }
    100% {
      background-color: #555; /* Back to darker grey */
    }
  }
`;

const ChartContainer = styled.div`
  height: 150px;
  margin-bottom: 15px;
  width: 100%;
`;

const DropdownSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const Container = styled.div`
  margin-bottom: 40px;
  position: relative;
`;



const PageTitle = styled.h1`
  display: flex;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
`;
const Description = styled.div`
  width: 60%;
  margin-bottom: 10px;
`;

const Clear = styled.div`
  font-size: 16px;
  color: #2d70e2;
  cursor: pointer;
  z-index: 99;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 20px;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  margin-bottom: 10px;
`;

const DropdownContainer = styled.div`
  position: relative;
  border: 1px solid lightgrey;
  padding: 5px;
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Selection = styled.div`
  font-size: 14px;
  color: #2a3039;
  margin-right: 5px;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 100;
  width: 200px;
`;

const Option = styled.div`
  padding: 10px 20px;
  font-size: 14px;
  color: #2a3039;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const ClearButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  &:focus {
    outline: none;
  }
`;

const Action = styled.div`
  font-size: 18px;
  width: 100%;
  text-align: flex-start;
`;

const Label = styled.div`
  font-size: 12px;
  color: #2a3039;
  margin-right: 10px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-top: 20px;
`;

const option = {
  series: [
    {
      name: "Very Negative",
      data: [3],
    },
    {
      name: "Slightly Negative",
      data: [25],
    },
    {
      name: "Neutral",
      data: [37],
    },
    {
      name: "Slightly Positive",
      data: [13],
    },
    {
      name: "Very Positive",
      data: [22],
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: 150,
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
      selection: {
        enabled: true,
        type: "single",
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "60%",
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ["Feedback sentiment"],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false, // Disable the x-axis border
      },
      axisTicks: {
        show: false, // Disable the x-axis ticks
      },
    },
    colors: ["#de2121", "#de2121", "#f7b531", "#33b054", "#33b054"],
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false, // Disable the x-axis border
      },
      axisTicks: {
        show: false, // Disable the x-axis ticks
      },
    },
    grid: {
      show: false, // Disable the grid
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " comments";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      markers: {
        width: 10,
        height: 10,
        radius: 12, // Makes the marker a circle
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "darken",
          value: 0.7,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "darken",
          value: 0.35,
        },
      },
    },
  },
};