import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import styled from "styled-components";
import { Loader } from "semantic-ui-react";
import {
  SG_GET_CHAT_ROOM,
  SG_FETCH_CHAT_ROOM,
  SET_WEB_CHAT_ID,
} from "constants/actions";
import { differenceInCalendarDays } from "date-fns";
import {
  addIcon,
  chatBubble,
  elipseDot,
  toolTip,
  removeIcon,
  deleteIcon,
} from "./Icons";

const shortenString = (str, maxLength) =>
  str?.length <= maxLength ? str : `${str?.slice(0, maxLength)}...`;

const getDaysAgo = (date) => {
  const daysAgo = differenceInCalendarDays(new Date(), new Date(date));
  return daysAgo;
};

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export default function ChatHistory(props) {
  const {
    selectedChatRoom,
    setSelectedChatRoom,
    setChatHistory,
    setNewChannel,
    chatLoading,
    loadingChatHistory,
    topicCreated,
    setDeleteQueue,
    columnWidths = {
      chat: "30%",
      date: "20%",
      tags: "30%",
      sampleSize: "10%",
      actions: "10%",
    },
  } = props;

  const dispatch = useDispatch();
  const [selectedRoom, setSelectedRoom] = useState();
  const [loadingMore, setLoadingMore] = useState(false);
  const [sortedConversation, setSortedConversation] = useState();
  const [totalChats, setTotalChats] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const { get_chat_topics } = useSelector(
    (state) => ({ get_chat_topics: state.ai_chat }),
    shallowEqual
  );

  useEffect(() => {
    if (get_chat_topics?.total_records) {
      setTotalChats(Number(get_chat_topics.total_records));
    }
  }, [get_chat_topics]);

  useEffect(() => {
    setPageSize(page * 12);
  }, [page]);

  useEffect(() => {
    setChatHistory(get_chat_topics?.[selectedChatRoom?.id]);
    if (!selectedChatRoom) {
      setSelectedRoom();
    }
  }, [get_chat_topics, selectedChatRoom, setChatHistory]);

  useEffect(() => {
    const fetchChatRoom = () => {
      dispatch({ type: SG_GET_CHAT_ROOM, payload: `per_page=${pageSize}` });
    };

    if (!sortedConversation) {
      const timer = setTimeout(fetchChatRoom, 2000);
      return () => clearTimeout(timer);
    }

    const timer1 = setTimeout(fetchChatRoom, 2000);
    const timer2 = setTimeout(fetchChatRoom, 5000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [dispatch, topicCreated, pageSize]);

  useEffect(() => {
    if (get_chat_topics?.chat_topics?.length > 0) {
      setSortedConversation(get_chat_topics.chat_topics);
      setLoadingMore(false);
    } else {
      setSortedConversation([]);
    }
  }, [get_chat_topics]);

  const handleConversationSelect = (conversation) => {
    setSelectedChatRoom({
      id: conversation.id,
      name: conversation.name,
      params: conversation.params,
    });
    setSelectedRoom(conversation);
    setNewChannel(conversation.id);
    dispatch({
      type: SET_WEB_CHAT_ID,
      payload: { id: conversation.id, name: conversation.name },
    });
    dispatch({ type: SG_FETCH_CHAT_ROOM, payload: { id: conversation.id } });
  };

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    setSortedConversation(
      sortedConversation.sort((a, b) => {
        if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
        if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
        return 0;
      })
    );
  };

  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <tr>
            <TableHeader
              width={columnWidths.chat}
              onClick={() => sortData("name")}
              isSorted={sortConfig.key === "name"}
              sortDirection={sortConfig.direction}
            >
              Chat
            </TableHeader>
            <TableHeader
              width={columnWidths.date}
              onClick={() => sortData("updated_at")}
              isSorted={sortConfig.key === "updated_at"}
              sortDirection={sortConfig.direction}
            >
              Last Message
            </TableHeader>
            <TableHeader
              width={columnWidths.tags}
              onClick={() => sortData("status")}
              isSorted={sortConfig.key === "status"}
              sortDirection={sortConfig.direction}
            >
              Tags
            </TableHeader>
            <TableHeader width={columnWidths.sampleSize}>
              Sample Size
            </TableHeader>
            <TableHeader width={columnWidths.actions} sortable={false}>
              Actions
            </TableHeader>
          </tr>
        </thead>
        <tbody>
          {loadingChatHistory &&
            !chatLoading &&
            !selectedChatRoom &&
            !sortedConversation?.length && (
              <tr>
                <td colSpan={4}>Loading</td>
              </tr>
            )}
          {sortedConversation?.length > 0 &&
            sortedConversation.map((conversation, index) => {
              const days = getDaysAgo(conversation.updated_at);
              const daysText = days === 0 ? "Today" : `${days} days ago`;
              const itemName = conversation?.name?.replaceAll("_", " ") + "...";

              return (
                <TableRow
                  key={conversation.id}
                  onClick={() => handleConversationSelect(conversation)}
                  hover
                >
                  <TableCell width={columnWidths.chat}>
                    {capitalizeFirstLetter(itemName)}
                  </TableCell>
                  <TableCell width={columnWidths.date}>{daysText}</TableCell>
                  <TableCell width={columnWidths.tags}>
                    {/* <Status>--</Status> */}
                    --
                  </TableCell>
                  <TableCell width={columnWidths.sampleSize}>--</TableCell>
                  <TableCell width={columnWidths.actions}>
                    <ActionIcons>
                      <DeleteIconWrapper
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the row click event from firing
                          setDeleteQueue(conversation.id);
                        }}
                      >
                        {deleteIcon("#2d70e2")}
                      </DeleteIconWrapper>
                    </ActionIcons>
                  </TableCell>
                </TableRow>
              );
            })}
        </tbody>
      </StyledTable>
      {pageSize < totalChats && !loadingMore && (
        <LoadMoreButton onClick={() => setPage(page + 1)}>
          Load more
        </LoadMoreButton>
      )}
      {loadingMore && (
        <div style={{ width: 220, display: "flex", justifyContent: "center" }}>
          <Loader active inline />
        </div>
      )}
    </TableContainer>
  );
}

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto; /* Allow horizontal scrolling only */
`;

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0 10px;
  table-layout: auto; /* Adjust table layout */
`;

const DeleteIconWrapper = styled.div`
  margin-left: 6px;
  align-self: center;
`;

const TableHeader = styled.th`
  padding: 10px;
  background-color: transparent;
  font-size: 14px;
  text-align: left;
  font-weight: 600;
  color: ${(props) => (props.isSorted ? "#1976d2" : "#4a4a4a")};
  width: ${(props) => props.width || "auto"};
  cursor: ${(props) => (props.sortable === false ? "default" : "pointer")};
  position: relative;

  &:after {
    content: "${(props) =>
      props.isSorted ? (props.sortDirection === "ascending" ? "▲" : "▼") : ""}";
    position: absolute;
    right: 10px;
  }
`;

const TableRow = styled.tr`
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 0.2s ease-in;
  &:hover {
    background-color: #f1f7ff;
  }
`;

const TableCell = styled.td`
  padding: 15px 10px;
  font-size: 14px;
  color: #666d79;
  width: ${(props) => props.width || "auto"};
`;

const Status = styled.div`
  background-color: #e6f7e9;
  color: #52c41a;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
`;

const ActionIcons = styled.div`
  display: flex;
  gap: 10px;
`;

const LoadMoreButton = styled.div`
  cursor: pointer;
  color: #2d70e2;
  border: 1px solid #2d70e2;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
`;