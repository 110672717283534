import React, { useState,useEffect } from 'react';

import styled, { keyframes } from "styled-components";
import Box from './Box'
import {useNavigate} from 'react-router-dom'
import Labels from './Labels';
import Back from 'ProtoType/SimpleDash/Back'
import AnalysisDashboard from '../AnalysisDashboard';
import Create from '../AnalysisDashboard/Create'

const History = () =>{
    const navigate = useNavigate();
    const [active,setActive] = useState(3)

    return (
        <Container>
            <Center>
                <Top>
                <Title>
                    Data Management
                   
                </Title>

                <ButtonContainer>
                <Button onClick={()=>navigate('/simple/prototype/groupdata/upload')}>Upload new data</Button>
                   
                   
                </ButtonContainer>
                </Top>
                
                <Tabs>
                   <Tab onClick={()=>setActive(3)} active={active==3}>History</Tab>
                   <Tab onClick={()=>setActive(2)} active={active==2}>Labels</Tab>

                </Tabs> 
                {active==1? 
                <AnalysisDashboard/>
                :active==2?
                <Box
                      title={'Regional franchise financial data'}
                      icon={'file'}
                      onClick={()=>navigate('/simple/prototype/groupdata/history/entry')}
                      description={'March 20th, 2024'}/>
                      : <Labels/>}  
              

            </Center>
        </Container>
    )

}

export default History


const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
    justify-content:center;
    align-items:center;
    font-family:'Raleway';
`

const Center = styled.div`
    width:800px;
    `

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    color:#2A3039;
    margin-bottom:20px;
    margin-top:20px;
    display:flex;
    align-items:center;
    justify-content:space-between;
`


const Tabs = styled.div`
    display:flex;
    width:100%;
    justify-content:flex-start;
    margin-bottom:20px;
    margin-top:30px;
`

const Tab = styled.div`
    padding:10px 20px;
    color:${props=>props.active ? 'blue' : '#2A3039'};
    border-bottom:${props=>props.active ?  '2px solid blue':'none'};
    margin-right:20px;
    cursor:pointer;
`

const Button = styled.div`
    color:#2A3039;
    font-size:14px;
    font-weight:600;
    cursor:pointer;
    margin-left:20px;
    font-family:'Raleway';
`

const ButtonContainer = styled.div`
    display:flex;
    align-items:center;
`

const Top = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:100%;
`