import { all, takeLatest, call, put, take, select, delay, race } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import {
  PENDING_WEBSOCKET,
  SAGA_CONNECT_WEBSOCKET,
  CONNECT_WEBSOCKET,
  SAGA_MESSAGE_WEBSOCKET,
  MESSAGE_WEBSOCKET,
  SAGA_START_AND_MESSAGE_WEBSOCKET,
  DISCONNECT_WEBSOCKET,
  ERROR_WEBSOCKET,
  ROOM_LOADING,
  CREATE_CHAT_ROOM,
  FETCH_FAILURE,
  ADD_PENDING_MESSAGE,
  SG_ADD_PENDING_MESSAGE,
} from "../constants/actions";
import * as api from "../constants/api";

import { WEBSOCKET_URL } from "../constants/api";

function* establishWebSocketSaga(action) {
  // const { token, name, web_chat_id } = action.payload;
  // const url = `ws://localhost:8000/ws-1/${token}/${name}?web_chat_id=${web_chat_id}`;
  const ws = new WebSocket(WEBSOCKET_URL(action.payload));

  yield new Promise((resolve) => {
    ws.onopen = resolve;
  });

  yield put({ type: CONNECT_WEBSOCKET, payload: ws });
}

function* establishWebSocket() {
  yield takeLatest(SAGA_CONNECT_WEBSOCKET, establishWebSocketSaga);
}

function createWebSocketChannel(ws) {
  return eventChannel((emit) => {
    const messageHandler = (event) => {
      // Assuming you receive the response message from the server
      emit({ type: MESSAGE_WEBSOCKET, payload: JSON.parse(event.data) });
    };

    const closeHandler = () => {
      // Handle WebSocket disconnection
      emit({ type: DISCONNECT_WEBSOCKET });
    };

    const errorHandler = (error) => {
      // Handle WebSocket errors
      emit({ type: ERROR_WEBSOCKET, payload: error });
    };

    // Add event listener for 'message' event
    ws.addEventListener("message", messageHandler);

    // Add event listener for 'close' event
    ws.addEventListener("close", closeHandler);

    // Add event listener for 'error' event
    ws.addEventListener("error", errorHandler);

    // Return the unsubscribe function
    return () => {
      // Clean up the event listeners when the channel is unsubscribed
      ws.removeEventListener("message", messageHandler);
      ws.removeEventListener("close", closeHandler);
      ws.removeEventListener("error", errorHandler);
    };
  });
}

function* handleSendMessage(action) {
  // Dispatch the client message
  yield put({ type: MESSAGE_WEBSOCKET, payload: action.payload });
  yield put({ type: PENDING_WEBSOCKET });

  // Access the WebSocket instance from the Redux store
  const ws = yield select((state) => state.ai_websocket.ws);

  if (ws && ws.readyState === WebSocket.OPEN) {
    // Create a WebSocket channel to capture the server responses
    const channel = yield call(createWebSocketChannel, ws);
    // Send the message
    ws.send(JSON.stringify(action.payload));

    // Listen for server responses
    while (true) {
      // Wait for the response from the WebSocket channel
      const responseAction = yield take(channel);

      // Dispatch the server response
      yield put(responseAction);
    }
  } else {
    console.log("WebSocket connection not open.");
    yield put({ type: DISCONNECT_WEBSOCKET, payload: false });
  }
}

// Watcher Saga
function* watchSendMessage() {
  yield takeLatest(SAGA_MESSAGE_WEBSOCKET, handleSendMessage);
}

// Saga to create a process and get the response ID
function* createProcess(action) {
  yield put({ type: ROOM_LOADING });
  try {
    const json = yield call(api.createChatRoom, action.payload);
    yield put({ type: CREATE_CHAT_ROOM, payload: json.data });
    // Return the response ID
    return json.data.id;
  } catch (e) {
    yield put({ type: FETCH_FAILURE, payload: e.message });
  }
}

function* waitForWebSocketOpen(ws) {
  yield new Promise((resolve, reject) => {
    const maxAttempts = 10; // Adjust the maximum number of attempts as needed
    let attempts = 0;

    const checkOpen = () => {
      if (ws.readyState === WebSocket.OPEN) {
        resolve();
      } else if (
        ws.readyState === WebSocket.CLOSED ||
        attempts >= maxAttempts
      ) {
        reject(new Error("WebSocket connection failed to open."));
      } else {
        attempts++;
        setTimeout(checkOpen, 100); // Adjust the delay time in milliseconds as needed
      }
    };

    checkOpen();
  });
}

function* handleStartandSendMessage(action) {
  const { name, message, token } = action.payload;

  // Call the createProcess saga to get the response ID
  const web_chat_id = yield call(createProcess, action);

  // const url = `ws://localhost:8000/ws-1/${token}/${name}?web_chat_id=${web_chat_id}`;
  const ws = new WebSocket(WEBSOCKET_URL({ name, token, web_chat_id }));;
  // Dispatch the client message
  yield put({ type: MESSAGE_WEBSOCKET, payload: message });

  // add the delay here to prevent race condition
  // Wait for the WebSocket connection to open with a delay

// `race` effect from Redux-Saga to wait for either 
// the WebSocket connection to open or a delay of 5 seconds. 
// If the WebSocket connection doesn't open within 5 seconds, 
// the `race` effect will timeout.

// To handle slow network connections, increase the delay time 
// and add a retry mechanism:

// This code will try to open the WebSocket connection up to `MAX_RETRIES` times. 
//If the WebSocket connection doesn't open within 10 seconds, 
//it will throw an error and retry the connection. 
//If it still can't open the connection after `MAX_RETRIES` attempts, 
//it will dispatch a `DISCONNECT_WEBSOCKET` action and break the loop.

  const MAX_RETRIES = 3; // Maximum number of retries
  let retries = 0;
  while (retries < MAX_RETRIES) {
    try {
      const result = yield race({
        connected: call(waitForWebSocketOpen, ws),
        timeout: delay(10000), // Increase the delay time to 10 seconds
      });
  
      if (result.connected) {
        yield put({ type: CONNECT_WEBSOCKET, payload: ws });
        break; // If the WebSocket connection opens, break the loop
      } else {
        throw new Error('WebSocket connection timeout');
      }
    } catch (error) {
      if (retries < MAX_RETRIES - 1) {
        retries += 1;
        console.log(`Retry attempt ${retries}...`);
      } else {
        console.error("Failed to open WebSocket connection:", error);
        yield put({ type: DISCONNECT_WEBSOCKET, payload: false });
        break;
      }
    }
  }

  // yield put({ type: CONNECT_WEBSOCKET, payload: ws });
  // console.log("WebSocket connection", ws);

  yield put({ type: PENDING_WEBSOCKET });

  // Access the WebSocket instance from the Redux store
  // const ws = yield select(state => state.websock.ws);

  if (ws && ws.readyState === WebSocket.OPEN) {
    // Create a WebSocket channel to capture the server responses
    const channel = yield call(createWebSocketChannel, ws);

    // console.log("Send the message", message);
    // Send the message
    ws.send(JSON.stringify(message));

    // console.log("Send the message channel", channel);
    // Listen for server responses
    while (true) {
      // Wait for the response from the WebSocket channel
      const responseAction = yield take(channel);
      // console.log("WebSocket connection responseAction.", responseAction);
      // Dispatch the server response
      yield put(responseAction);
    }
  } else {
    // console.log("WebSocket connection not open.");
    yield put({ type: DISCONNECT_WEBSOCKET, payload: false });
  }
}
// Watcher Saga
function* watchStartAndSendMessage() {
  yield takeLatest(SAGA_START_AND_MESSAGE_WEBSOCKET, handleStartandSendMessage);
}

function* addPendingMessage(action) {
  yield put({ type: SG_ADD_PENDING_MESSAGE, payload: action.payload });
}

// Watcher Saga
function* watchAddPendingMessage() {
  yield takeLatest(ADD_PENDING_MESSAGE, addPendingMessage);
}

export default function* rootSaga() {
  yield all([
    establishWebSocket(),
    watchSendMessage(),
    watchStartAndSendMessage(),
    // Other sagas
    watchAddPendingMessage(),
  ]);
}
