// MasterComponent.jsx
import React, { useState, useEffect} from 'react';
import styled, { keyframes } from 'styled-components';
import { Container, Step } from 'semantic-ui-react';
import ChartStep from './ChartStep';
import AIContentStep from './AIContentStep';
import CompletedStep from './CompletedStep';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const MasterContainer = styled(Container)`
  padding: 2em;
  animation: ${fadeIn} 0.5s ease-in;
  width:100%;
`;

const Top = styled.div`
    font-size:14px;
    color:#333333;
    margin-bottom:10px;
`

const Current = styled.div`
    font-size:24px;
    font-weight:700;
    color:#333333;
    margin-bottom:30px;
`

const steps = [
  { key: 'chart', title: 'Generating Chart', description: 'Creating visual data representation.' },
  { key: 'ai', title: 'Generating AI Content', description: 'Crafting insightful analysis.' },
  { key: 'completed', title: 'Report Completed', description: 'Your report is ready.' },
];

const MasterComponent = ({data,filters,anchor,core_data}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [chartDataURI, setChartDataURI] = useState(null);
  const [aiContent, setAiContent] = useState(null);

  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  useEffect(() => {
    console.log(chartDataURI)
    }, [chartDataURI])

  return (
    <MasterContainer>
        <Top>Building content</Top>
        <Current>{steps[currentStep].title}</Current>


      {currentStep === 0 && (
        <ChartStep 
        data={data}
        filters={filters}
        anchor={anchor}
        core_data={core_data}
        onComplete={(uri) => { setChartDataURI(uri); handleNextStep(); }} />
      )}

      {currentStep === 1 && (
        <AIContentStep 
          chartURI={chartDataURI} 
          onComplete={(content) => { setAiContent(content); handleNextStep(); }} 
        />
      )}

      {currentStep === 2 && (
        <CompletedStep chartURI={chartDataURI} aiContent={aiContent} />
      )}
    </MasterContainer>
  );
};

export default MasterComponent;
