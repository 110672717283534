import React from 'react';
import styled, { keyframes } from 'styled-components';
import {Icon} from 'semantic-ui-react';


const MethodSelection = ({ onSelectMethod,goBack }) => (
  <Container>
    <BackButton onClick={goBack}><Icon name="chevron left"/> Back</BackButton>
    <Title>Choose the diagnostic method you want to use to build your action plan.</Title>
    <Description>
    Each method below offers a different lens on your culture data, allowing you to choose the perspective that best suits the way you want to take action.

    </Description>

    <DiagnosticCard
        onClick={() => onSelectMethod('ROI')}
        recommended={true}
        title="ROI based predictions"
        description="This approach leverages predictive modelling to identify the culture factors that will have the highest return on investment (ROI) based on your organization’s unique data."
    />

    <DiagnosticCard
        onClick={() => onSelectMethod('Benchmark')}
        title="Global benchmark comparison"
        description="This approach compares your organization’s culture scores to global or industry benchmarks, offering a context for where you stand relative to others."
    />

    <DiagnosticCard
        onClick={() => onSelectMethod('RawScore')}
        title="Raw scores"
        description="This approach offers a straightforward view of your culture scores, allowing you to see each factor’s current state without additional weighting or comparison."
    />


    
  </Container>
);


const DiagnosticCard = ({title,description,onClick,best,recommended}) =>{
    return (
        <Card onClick={onClick}>
          
            <CardTitle>
                {title}
            </CardTitle>

            <CardDescription>
                {description}
            </CardDescription>
        </Card>
    )
}

export default MethodSelection;



const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const BackButton = styled.div`
    color: #2D70E2;
    font-size: 14px;
    cursor: pointer;
`

const Container = styled.div`
     opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
`
const Title = styled.div`
    font-size:22px;
    font-weight:bold;
    margin-bottom:20px;
    margin-top:10px;
    line-height:1.3;
`

const Card = styled.div`
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 20px;
    font-family: Raleway, sans-serif;
    cursor: pointer;
    width:100%;
    margin-bottom: 20px;
    position:relative;

    &:hover {
      background-color: ${({ isSelected }) => (isSelected ? '#d0e4ff' : '#f8f8f8')};
      border-color: #2D70E2;
    }
`

const CardTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
`

const CardDescription = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
`

const Description = styled.div`
    font-family: Raleway, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
`

const Recommended = styled.div`
    position:absolute;
    top:20px;
    right:10px;
    font-size: 12px;
    background-color: #2D70E2;
    padding:3px 10px;
    color: white;
    border-radius: 8px;
    font-weight: bold;
    font-family: Raleway, sans-serif;

`